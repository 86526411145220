import { useCallback, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import { IconDefinition as IconDefinitionSvg } from "@fortawesome/fontawesome-svg-core"
import cx from "classnames"

import ProjectCard from "../../components/ProjectCard"
import { ProjectDataTypes } from "@/types/program"

interface ArrowTypes {
  show: boolean
  onClick: () => void
  icon: IconDefinitionSvg
  className: string
}

interface ProgramsCarouselTypes {
  programsData: ProjectDataTypes[]
  title: string
}

const Arrow = ({ show, onClick, icon, className }: ArrowTypes) => {
  if (!show) {
    return null
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className={cx(
        "similar-programs-slider-arrow bg-white absolute top-1/2 -translate-y-1/2",
        className
      )}
    >
      <FontAwesomeIcon icon={icon} />
    </button>
  )
}

const ProgramsCarousel = ({
  programsData = [],
  title,
}: ProgramsCarouselTypes) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === programsData.length - 1 ? 0 : prevIndex + 1
    )
  }, [programsData.length])

  const prevSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? programsData.length - 1 : prevIndex - 1
    )
  }, [programsData.length])

  if (programsData.length === 0) {
    return null
  }

  return (
    <div className="card card-content px-6 pt-6 pb-4 mb-6">
      <h4 className="text-overline mb-3">{title}</h4>

      <div className="relative">
        <div className="relative w-full max-w-4xl mx-auto overflow-hidden">
          <div
            className="flex transition-transform duration-500 ease-in-out"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {programsData.map((program: ProjectDataTypes) => (
              <div className="w-full flex-shrink-0" key={program.id}>
                <ProjectCard
                  className={cx(
                    "w-full",
                    `sponsored-program sponsored-${program.id}`
                  )}
                  programData={program}
                  showMinimalInfo={true}
                />
              </div>
            ))}
          </div>
        </div>

        <Arrow
          show={currentIndex > 0}
          onClick={prevSlide}
          icon={faArrowLeft}
          className="-left-2 sponsored-left"
        />

        <Arrow
          show={currentIndex < programsData?.length - 1}
          onClick={nextSlide}
          icon={faArrowRight}
          className="-right-2 sponsored-right"
        />
      </div>
    </div>
  )
}

export default ProgramsCarousel
