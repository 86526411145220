import { ReactNode } from "react"
import cx from "classnames"

import useMediaQuery from "../../hooks/useMediaQuery"

interface EligibilityQuizBtnContainerTypes {
  children: ReactNode
}

const EligibilityQuizBtnContainer = ({
  children,
}: EligibilityQuizBtnContainerTypes) => {
  const isMaxWidthMd = useMediaQuery("(max-width: 767px)")

  return (
    <div
      className={cx("flex flex-col-reverse lg:flex-row gap-6", {
        "fixed bottom-0 left-0 right-0 bg-white border-t border-dusk-50 px-4 py-6 ":
          isMaxWidthMd,
      })}
    >
      {children}
    </div>
  )
}

export default EligibilityQuizBtnContainer
