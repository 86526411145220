import HIGHLIGHT_1 from "../../images/become-a-member-highlights-1.jpg"
import HIGHLIGHT_2 from "../../images/become-a-member-highlights-2.jpg"
import HIGHLIGHT_3 from "../../images/become-a-member-highlights-3.jpg"

const BecomeAMemberHighlights = () => {
  return (
    <div className="w-full max-w-[1052px] mx-auto mt-10">
      <h3 className="text-2xl text-autumn text-center font-semibold leading-[124%] tracking-0.48">
        Membership highlights
      </h3>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-5">
        <div className="w-full flex justify-center">
          <img
            src={HIGHLIGHT_1}
            alt="Eligibility map"
            className="block w-full max-w-[456px] h-auto"
          />
        </div>

        <div className="w-full flex justify-center">
          <img
            src={HIGHLIGHT_2}
            alt="Program overview"
            className="block w-full max-w-[456px] h-auto"
          />
        </div>

        <div className="w-full flex justify-center">
          <img
            src={HIGHLIGHT_3}
            alt="Loss detection"
            className="block w-full max-w-[456px] h-auto"
          />
        </div>
      </div>
    </div>
  )
}

export default BecomeAMemberHighlights
