import { ReactElement } from "react"
import { TooltipReference, useTooltipState } from "reakit/Tooltip"
import cx from "classnames"

import { TooltipContent } from "./ToolTip"
import { hasRequiredPermission } from "../utils/hasRequiredPermission"
import { AccountRoleType } from "@/types/account"

const CANNOT_MESSAGE = {
  admin: "You cannot take this action as an admin",
  editor:
    "You cannot take this action as an editor.  Please contact the account admin for assistance.",
  viewer:
    "You cannot take this action as a viewer. Please contact the account admin for assistance.",
}

interface ActionPermissionWrapperProps {
  accountRole: AccountRoleType | undefined
  action: string
  children: ReactElement<any, any>
  disabled?: boolean
  className?: string
}

export const ActionPermissionWrapper = ({
  accountRole,
  action,
  children: trigger,
  disabled,
  className,
}: ActionPermissionWrapperProps) => {
  const tooltip = useTooltipState()
  const missingPermission = !hasRequiredPermission({
    action,
    accountRole,
  })

  disabled = disabled || missingPermission

  return (
    <>
      <TooltipReference
        state={tooltip}
        as="span"
        tabIndex={disabled ? 0 : -1}
        className={className}
      >
        <trigger.type
          {...trigger.props}
          disabled={trigger.props.disabled || disabled}
          className={cx(
            trigger.props.className as string,
            disabled && "pointer-events-none"
          )}
        />
      </TooltipReference>

      {missingPermission && (
        <TooltipContent tooltip={tooltip}>
          {CANNOT_MESSAGE[accountRole as keyof typeof CANNOT_MESSAGE]}
        </TooltipContent>
      )}
    </>
  )
}
