import cx from "classnames"

import ToggleSwitch from "../../../components/ToggleSwitch"

interface FilterSwitchTypes {
  label: string
  value: boolean
  onChange: () => void
  className?: string
}

const FilterSwitch = ({
  label,
  value,
  onChange,
  className,
}: FilterSwitchTypes) => (
  <div className={cx("project-filters-switch", className)}>
    <ToggleSwitch label={label} value={value} onChange={onChange} />
    <span>{value ? "Show" : "Hide"}</span>
  </div>
)

export default FilterSwitch
