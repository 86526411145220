import { Toast } from "../../components/Toast"
import { ContentCard } from "../../components/Card"
import ProjectInterestForm from "../ProjectInterest/ProjectInterestForm"
import { AccountTypes } from "@/types/account"

interface ProgramInterestProps {
  account: AccountTypes
}

const ProgramInterest = ({ account }: ProgramInterestProps) => {
  if (account?.has_project_interest_info) {
    return null
  }

  return (
    <ContentCard className="card-content" roundedMd>
      <h3 className="text-xl mb-6">
        Tell us what type of programs you're interested in
      </h3>

      <p className="text-overline">Program Interest</p>

      <p className="mt-2 mb-8 md:mb-6 text-base leading-130">
        We'll use this information to share opportunities that you're interested
        in.
      </p>

      <ProjectInterestForm
        accountData={account}
        submitText="Save Program Interest"
        onSuccess={() => Toast.success(`Program interest added to account`)}
      />
    </ContentCard>
  )
}

export default ProgramInterest
