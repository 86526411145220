import { ReactNode } from "react"
import cx from "classnames"

interface BecomeAMemberWidgetOptionProps {
  heading: string
  subheading: string
  enabled?: boolean
  active: boolean
  onClick: () => void
  children: ReactNode
}

const BecomeAMemberWidgetOption = ({
  heading,
  subheading,
  enabled = true,
  active,
  onClick,
  children,
}: BecomeAMemberWidgetOptionProps) => {
  return (
    <div
      tabIndex={0}
      className={cx(
        "block relative border border-dusk-50 transition-colors rounded select-none pt-5 pr-4 pb-[38px] pl-7",
        {
          "hover:border-dusk-100 cursor-pointer": enabled,
        },
        { "cursor-not-allowed": !enabled },
        {
          "bg-sand after:content-[''] after:absolute after:-inset-px after:border-[3px] after:border-solid after:border-autumn after:rounded after:pointer-events-none":
            active,
        }
      )}
      onClick={onClick}
    >
      <p className="text-charcoal-500 text-3xl font-medium leading-[124%] tracking-[0.6px]">
        {heading}
      </p>

      <p className="text-[#5C5C59] text-base leading-[140%] tracking-0.32 mt-2">
        {subheading}
      </p>

      <ul className="mt-5">{children}</ul>
    </div>
  )
}

export default BecomeAMemberWidgetOption
