import { useState } from "react"
import { useQueryClient } from "@tanstack/react-query"

import { ContentCard } from "../../components/Card"
import LandStats from "./Land/LandStats"
import LandText from "./Land/LandText"
import { Profile } from "@/types"
import { AccountProperty } from "@/types/property"
import LandTabs from "./Land/LandTabs"
import LandHeader from "./Land/LandHeader"
import LandMap from "./Land/LandMap"
import { useChangeDetection } from "../../hooks"
import { TileURLsTypes } from "@/types/tiles"

export type ActiveTabType = "land-cover" | "loss-detection"

interface LandProps {
  accountId: string
  profile: Profile
  property: AccountProperty
  isMember: boolean
}

const Land = ({ accountId, profile, property, isMember }: LandProps) => {
  const queryClient = useQueryClient()
  const [activeTab, setActiveTab] = useState<ActiveTabType>("loss-detection")

  const { data: changeDetectionData, isLoading: changeDetectionIsLoading } =
    useChangeDetection<TileURLsTypes, Error>(queryClient, accountId)

  // there is an assessment but no acreage data
  const noDetections =
    property.change_data !== null &&
    (property.change_data?.major_loss === 0 ||
      property.change_data?.major_loss === null) &&
    (property.change_data?.minor_loss === 0 ||
      property.change_data?.minor_loss === null)

  // if change_data is null, we haven't run assessment yet
  const dataNotReady =
    property.change_data === null || property.change_data === undefined

  return (
    <ContentCard className="card-content landcover-content dashboard" roundedMd>
      <LandHeader firstName={profile.first_name} acreage={property.acreage} />

      <LandTabs activeTab={activeTab} setActiveTab={setActiveTab} />

      <LandStats
        activeTab={activeTab}
        property={property}
        isMember={isMember}
        date={changeDetectionData?.date}
      />

      <LandMap
        accountId={accountId}
        isMember={isMember}
        activeTab={activeTab}
        noDetections={noDetections}
        dataNotReady={dataNotReady}
        changeDetectionData={changeDetectionData}
        changeDetectionIsLoading={changeDetectionIsLoading}
      />

      <LandText activeTab={activeTab} />
    </ContentCard>
  )
}

export default Land
