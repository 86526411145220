import { useParams } from "react-router"

import Pagination from "../../components/Pagination"
import { getPrevAndNext } from "../../shared/getPrevAndNext"
import { useAccountId, useIsMultiAccount } from "../../hooks"
import { useHeapTrackOnView } from "../../hooks/useHeapTrackOnView"
import { ProgramType } from "@/types/program"

interface ProjectDetailsPaginationProps {
  filteredProjectsData: ProgramType[]
}

const ProjectDetailsPagination = ({
  filteredProjectsData,
}: ProjectDetailsPaginationProps) => {
  const accountId = useAccountId()
  const { projectId } = useParams()
  const isMultiAccount = useIsMultiAccount()

  const { prevPage, nextPage } = getPrevAndNext(
    filteredProjectsData,
    Number(projectId)
  )

  const paginationRef = useHeapTrackOnView({
    event: "componentview-program-pagination",
    properties: { program: projectId },
    observerOptions: { threshold: 0.5 },
  })

  return (
    <div ref={paginationRef}>
      <Pagination
        prevTo={
          prevPage && isMultiAccount
            ? `/accounts/${accountId}/programs/${prevPage}`
            : prevPage && !isMultiAccount
              ? `/programs/${prevPage}`
              : undefined
        }
        nextTo={
          nextPage && isMultiAccount
            ? `/accounts/${accountId}/programs/${nextPage}`
            : nextPage && !isMultiAccount
              ? `/programs/${nextPage}`
              : !nextPage && isMultiAccount
                ? `/accounts/${accountId}/programs/browsed-all`
                : !nextPage && !isMultiAccount
                  ? "/programs/browsed-all"
                  : undefined
        }
        prevLinkText="Previous program"
        nextLinkText="Next program"
        className="mb-6 lg:mb-12"
      />
    </div>
  )
}

export default ProjectDetailsPagination
