import { DateTime } from "luxon"

import { useIntersectionObserver } from "./useIntersectionObserver"
import { heapTrackEvent } from "../api/integrations"

interface UseHeapTrackOnViewOptions {
  event: string
  properties?: Record<string, any>
  observerOptions?: IntersectionObserverInit
}

/*
  Example of usage in component:
  const elementRef = useHeapTrackOnView({
    event: "componentview-friendly-name",
    properties: { feature: "Description" },
    observerOptions: { threshold: 0.5 },
  })
*/

export const useHeapTrackOnView = ({
  event,
  properties = {},
  observerOptions = { threshold: 0.5 },
}: UseHeapTrackOnViewOptions) => {
  const date = DateTime.fromISO(new Date().toISOString()).toFormat(
    "LLLL dd, hh:mm:ss a"
  )

  const handleView = () => {
    heapTrackEvent(event, { ...properties, timestamp: date })
  }

  const ref = useIntersectionObserver(handleView, observerOptions)

  return ref
}
