import { ReactNode } from "react"
import cx from "classnames"

import useMediaQuery from "../../hooks/useMediaQuery"

interface OnboardingSurveyHeaderProps {
  text: ReactNode
  subheader?: string | null
  headerImg: ReactNode
}

const OnboardingSurveyHeader = ({
  text,
  subheader,
  headerImg,
}: OnboardingSurveyHeaderProps) => {
  const isMaxWidthMd = useMediaQuery("(max-width: 767px)")

  return (
    <div className={cx(isMaxWidthMd ? "" : "mt-14")}>
      <h2 className="text-charcoal-500 text-xl lg:text-2xl leading-120 tracking-0.4 md:tracking-0.48 mt-2">
        {text}
      </h2>
      {subheader && (
        <div className="text-dusk text-base leading-130 tracking-0.32 mt-3">
          {subheader}
        </div>
      )}

      {isMaxWidthMd ? (
        <div className="w-full flex justify-center mt-8 md:mt-12">
          {headerImg}
        </div>
      ) : null}
    </div>
  )
}

export default OnboardingSurveyHeader
