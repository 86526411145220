import { ReactNode } from "react"
import cx from "classnames"

import CHECKMARK from "../images/check_24px.png"

interface IconListType {
  className?: string
  children: ReactNode
}

const CheckmarkListItem = ({ className, children }: IconListType) => (
  <li
    className={cx(
      "flex items-start gap-x-3 text-sm text-[#5C5C59] leading-[140%] tracking-[0.28px]",
      className
    )}
  >
    <img src={CHECKMARK} alt="checkmark" />
    {children}
  </li>
)

export default CheckmarkListItem
