import { Link } from "react-router"
import cx from "classnames"

import { eligibilityQuizBackground } from "../../EligibilityQuiz/helpers"
import { useAccountUrlPrefix } from "../../../hooks"
import { EligibilityQuizCategoryType } from "../../../types/constants"
import { EligibilityQuizzes } from "@/types/eligibility"

interface QuizBannerTypes {
  category: EligibilityQuizCategoryType | undefined
  quizesData: EligibilityQuizzes | undefined
  show: boolean
}

const QuizBanner = ({ category, quizesData, show }: QuizBannerTypes) => {
  const accountUrlPrefix = useAccountUrlPrefix()
  const bgImage = eligibilityQuizBackground(category)

  if (!show) {
    return null
  }

  const completed =
    quizesData && quizesData[category as keyof typeof quizesData].completed

  return (
    <Link
      to={`${accountUrlPrefix}/eligibility-quiz/${category}`}
      className={cx(
        "quiz-banner",
        category,
        "block w-full bg-center rounded shadow-banner p-4 md:px-12 md:py-6"
      )}
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(36, 36, 38, 0.60) 0%, rgba(36, 36, 38, 0.60) 100%), url(${bgImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition:
          category === "regen_ag"
            ? "50% 40%"
            : category === "general"
              ? "50% 8%"
              : "50%",
      }}
      state={{ completed }}
    >
      <h3 className="text-white text-lg md:text-xl leading-130 md:leading-120 tracking-0.378 md:tracking-0.4">
        Discover Your Eligibility
      </h3>

      <p className="text-white text-base leading-130 tracking 0.32 my-2">
        Answer questions about your land and check your program eligibility in a
        few minutes.
      </p>

      <p className="text-west-side text-base font-bold leading-130 tracking-0.32">
        Get Started
      </p>
    </Link>
  )
}

export default QuizBanner
