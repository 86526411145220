import { RefObject } from "react"

import PageHero from "./PageHero"
import CategoryFilter, { FilterType } from "./CategoryFilter/CategoryFilter"
import ProjectFilters from "./ProjectFIlters/ProjectFilters"
import NothingFound from "../../components/NothingFound"
import SelectMenu, { OptionType } from "../../components/SelectMenu"
import Banners from "./Banners/Banners"
import InvitationsBanner from "./InvitationsBanner"
import ProjectCards from "./ProjectCards"
import IneligibleProgramsToggleSwitch from "./IneligibleProgramsToggleSwitch"
import NumericPagination from "../../components/NumericPagination"
import { useAccountId, useIsMultiAccount } from "../../hooks"
import { useFirstPageRedirect } from "../../hooks/useFirstPageRedirect"
import { ProjectDataTypes, ProjectListTypes } from "../../types/program"
import { Profile } from "../../types"
import { NotificationTypes } from "../../types/notifications"
import { DropdownOptionsType } from "./types"
import { EligibilityQuizzes } from "../../types/eligibility"
import { EligibilityQuizCategoryType } from "../../types/constants"
import { AccountTypes } from "../../types/account"

interface ProjectListingContentTypes {
  data: ProjectListTypes | undefined
  profile: Profile | undefined
  account: AccountTypes | undefined
  projectsData: ProjectDataTypes[]
  invitesData: NotificationTypes[] | undefined
  projectTypeFilters: FilterType[]
  dropdownOptions: DropdownOptionsType[]
  quizesData: EligibilityQuizzes | undefined
  quizCategory: EligibilityQuizCategoryType | undefined
  projectsDataIneligibleOnLength: number
  pageId: string | undefined
  listingRef: RefObject<HTMLDivElement | null>
  sortBy: string
  handleSortBy: (value: OptionType) => void
}

const PageSize: number = 12

const ProjectListingContent = ({
  data,
  profile,
  account,
  projectsData,
  invitesData,
  projectTypeFilters,
  dropdownOptions,
  quizesData,
  quizCategory,
  projectsDataIneligibleOnLength,
  pageId,
  listingRef,
  sortBy,
  handleSortBy,
}: ProjectListingContentTypes) => {
  const accountId = useAccountId()
  const isMultiAccount = useIsMultiAccount()
  const handleFirstPageRedirect = useFirstPageRedirect()

  if (profile === null || profile === undefined) {
    return null
  }

  if (data?.project_data?.length === 0) {
    return (
      <div className="bg-cloud-50">
        <PageHero handleFirstPageRedirect={handleFirstPageRedirect} />

        <NothingFound
          title="No programs found"
          text="There's no program opportunities available for review. Check back soon!"
          linkText="View Dashboard"
          to="/"
        />
      </div>
    )
  }

  return (
    <div className="bg-cloud-50">
      <PageHero handleFirstPageRedirect={handleFirstPageRedirect} />

      <CategoryFilter
        filters={projectTypeFilters}
        handleFirstPageRedirect={handleFirstPageRedirect}
      />

      <div className="container max-w-6xl overflow-x-hidden sticky md:static top-16 md:top-auto z-[9] bg-white shadow-dropdown md:shadow-none">
        <div className="flex flex-wrap flex-row items-center gap-4 pt-6 pb-6">
          <ProjectFilters
            filteredNumber={projectsData.length}
            handleFirstPageRedirect={handleFirstPageRedirect}
          />

          <IneligibleProgramsToggleSwitch
            handleFirstPageRedirect={handleFirstPageRedirect}
          />

          <SelectMenu
            className="order-1 xl:order-3 min-w-[252px]"
            menuClassName="min-w-[252px]"
            options={dropdownOptions}
            label="Sort by"
            showSelected={true}
            selectedOption={sortBy}
            setSelectedOption={handleSortBy}
            onSelect={handleFirstPageRedirect}
            placement="bottom-end"
            gutter={10}
          />
        </div>
      </div>

      <Banners
        account={account}
        quizesData={quizesData}
        quizCategory={quizCategory}
      />

      <InvitationsBanner invitesData={invitesData} />

      <div ref={listingRef} className="container max-w-6xl mb-12 mt-6 md:mt-0">
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
          <ProjectCards
            pageSize={PageSize}
            pageId={pageId}
            projectsData={projectsData}
            projectsDataIneligibleOnLength={projectsDataIneligibleOnLength}
          />
        </div>

        <NumericPagination
          show={!!projectsData}
          currentPage={Number(pageId)}
          totalCount={projectsData.length}
          pageSize={PageSize}
          urlRoot={
            isMultiAccount
              ? `/accounts/${accountId}/programs/page`
              : "/programs/page"
          }
          className="mt-18"
        />
      </div>
    </div>
  )
}

export default ProjectListingContent
