import { useState, useRef, useEffect, useMemo } from "react"

import { useProjectsStore } from "../../../stores/useProjectsStore"
import useMediaQuery from "../../../hooks/useMediaQuery"
import FiltersOpen from "./FiltersOpen"
import ProjectFiltersDrawer from "./ProjectFiltersDrawer"

interface ProjectFiltersTypes {
  filteredNumber: number
  handleFirstPageRedirect: () => void
}

const ProjectFilters = ({
  filteredNumber,
  handleFirstPageRedirect,
}: ProjectFiltersTypes) => {
  const isMaxWidthXl = useMediaQuery("(max-width: 1279px)")
  const overlayRef = useRef<HTMLDivElement>(null)
  const filtersRef = useRef<HTMLDivElement>(null)
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false)
  const {
    govtProjectsOn,
    ineligibleProjectsOn,
    hasLandownerCost,
    termLengthFilter,
    paymentTypes,
    setTermLengthFilter,
  } = useProjectsStore()

  const [termLength, setTermLength] = useState(termLengthFilter)

  useEffect(() => {
    setTermLengthFilter(termLength)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termLength])

  let activeFilters = useMemo(
    () => [
      !govtProjectsOn,
      !hasLandownerCost,
      termLengthFilter[0] > 0 || termLengthFilter[1] < 100,
      Object.values(paymentTypes).includes(true),
    ],
    [govtProjectsOn, hasLandownerCost, termLengthFilter, paymentTypes]
  )

  // DEV: include government projects toggle in active filters number on mobile
  if (isMaxWidthXl) {
    activeFilters = [...activeFilters, !ineligibleProjectsOn]
  }

  const activeFiltersNum = useMemo(
    () => activeFilters.filter(Boolean).length,
    [activeFilters]
  )

  const handleTermLength = (e: [number, number]) => {
    setTermLength(e)
  }

  const handleOpen = () => {
    setFiltersOpen(true)
  }

  const handleClose = () => {
    setFiltersOpen(false)
  }

  return (
    <div className="project-filters">
      <FiltersOpen onClick={handleOpen} activeFiltersNum={activeFiltersNum} />

      <ProjectFiltersDrawer
        overlayRef={overlayRef}
        filtersRef={filtersRef}
        filtersOpen={filtersOpen}
        handleClose={handleClose}
        handleFirstPageRedirect={handleFirstPageRedirect}
        handleTermLength={handleTermLength}
        filteredNumber={filteredNumber}
      />
    </div>
  )
}

export default ProjectFilters
