import BookAnExpertConsultation from "./BookAnExpertConsultation"
import CompleteSurveyNotification from "./CompleteSurveyNotification"
import MembershipBanner from "./MembershipBanner"
import NaturalCapitalEstimator from "./NaturalCapitalEstimator/NaturalCapitalEstimator"
import NaturalCapitalPortfolio from "./NaturalCapitalPortfolio"
import NotificationContent from "./NotificationContent"
import ProgramInterest from "./ProgramInterest"
import Land from "./Land"
import AccountStatus from "./AccountStatus"
import DashboardAccountInfo from "./DashboardAccountInfo"
import ProgramsCarousel from "./ProgramsCarousel"
import PartnerInvites from "./PartnerInvites"
import InviteYourNeighbor from "./InviteYourNeighbor"
import { useAccountId } from "../../hooks"
import useFeatureFlags from "../../hooks/useFeatureFlags"
import { FEATURE_FLAGS } from "../../shared/constants"
import { AccountTypes } from "@/types/account"
import { DashboardAccountsTypes } from "@/types/dashboardAccounts"
import { NotificationTypes } from "@/types/notifications"
import { AccountInterestTypes } from "@/types/accountInterest"
import { AccountProperty } from "@/types/property"
import { EligibilityQuizzes } from "@/types/eligibility"
import { Profile } from "@/types"
import { ProjectDataTypes } from "@/types/program"

interface ViewAccountContentProps {
  account: AccountTypes | undefined
  accounts: DashboardAccountsTypes | undefined
  notifications: NotificationTypes[] | undefined
  showSurveyNotification: boolean
  accountInterestData: AccountInterestTypes | undefined
  handleShowSurveyNotification: (newValue: boolean) => void
  property: AccountProperty | undefined
  quizzesData: EligibilityQuizzes | undefined
  profile: Profile | undefined
  sponsoredPrograms: ProjectDataTypes[]
  isCheckoutSuccess: boolean
}

const ViewAccountContent = ({
  account,
  accounts,
  notifications,
  showSurveyNotification,
  accountInterestData,
  handleShowSurveyNotification,
  property,
  quizzesData,
  profile,
  sponsoredPrograms,
  isCheckoutSuccess,
}: ViewAccountContentProps) => {
  const accountId = useAccountId()
  const { flagEnabled: nciRolloutEnabled, clientReady } = useFeatureFlags(
    FEATURE_FLAGS.nci_rollout
  )

  const accountsCount: number | undefined = accounts && accounts.count

  const dataLoaded = !!(
    account &&
    accounts &&
    property &&
    notifications &&
    profile &&
    quizzesData &&
    accountInterestData
  )

  if (!dataLoaded) {
    return null
  }

  return (
    <div className="grid grid-cols-1 gap-6  md:grid-cols-3 md:gap-6">
      <MembershipBanner isMember={account?.is_member} />

      <div className="md:col-span-2 space-y-8">
        <NotificationContent
          accountId={accountId}
          accountsCount={accountsCount}
          notifications={notifications}
        />

        <CompleteSurveyNotification
          show={
            showSurveyNotification &&
            !accountInterestData?.has_answered_all_questions
          }
          onDismiss={() => handleShowSurveyNotification(false)}
        />

        <BookAnExpertConsultation
          show={
            isCheckoutSuccess === false &&
            !!account &&
            account?.is_member &&
            account?.has_purchased_nca_report === false
          }
          accountRole={account?.role}
          isMember={account?.is_member}
        />

        <NaturalCapitalEstimator
          show={
            clientReady &&
            !!nciRolloutEnabled &&
            !!property &&
            property.index_data &&
            (property.landcover_data?.forest?.acres ?? 0) >= 5
          }
          indexData={property?.index_data}
          hasPurchasedNCAReport={!!account?.has_purchased_nca_report}
          isMember={!!account?.is_member}
        />

        <NaturalCapitalPortfolio quizzesData={quizzesData} />

        <ProgramInterest account={account} />

        <Land
          accountId={accountId}
          profile={profile}
          property={property}
          isMember={account.is_member}
        />
      </div>

      <div>
        <AccountStatus
          isMember={account?.is_member}
          memberSince={account?.member_since_date}
        />

        <DashboardAccountInfo
          acreage={property.acreage}
          accountInterestData={accountInterestData}
        />

        <ProgramsCarousel
          programsData={sponsoredPrograms}
          title="Partner Sponsored Programs"
        />

        <PartnerInvites />

        <InviteYourNeighbor />
      </div>
    </div>
  )
}

export default ViewAccountContent
