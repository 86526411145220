import FilterSwitch from "./FilterSwitch"
import { useProjectsStore } from "../../../stores/useProjectsStore"

interface ProjectFiltersDrawerFilterSwitchesProps {
  handleFirstPageRedirect: () => void
}

const ProjectFiltersDrawerFilterSwitches = ({
  handleFirstPageRedirect,
}: ProjectFiltersDrawerFilterSwitchesProps) => {
  const {
    govtProjectsOn,
    ineligibleProjectsOn,
    hasLandownerCost,
    setGovtProjectsOn,
    setIneligibleProjectsOn,
    setHasLandownerCost,
  } = useProjectsStore()

  return (
    <div className="project-filters-inner-content-container">
      <FilterSwitch
        label="Government programs"
        value={govtProjectsOn}
        onChange={() => {
          setGovtProjectsOn(!govtProjectsOn)
          handleFirstPageRedirect()
        }}
      />

      <FilterSwitch
        label="Ineligible programs"
        value={ineligibleProjectsOn}
        onChange={() => {
          setIneligibleProjectsOn(!ineligibleProjectsOn)
          handleFirstPageRedirect()
        }}
        className="flex xl:hidden"
      />

      <FilterSwitch
        label="Has landowner cost"
        value={hasLandownerCost}
        onChange={() => {
          setHasLandownerCost(!hasLandownerCost)
          handleFirstPageRedirect()
        }}
      />
    </div>
  )
}

export default ProjectFiltersDrawerFilterSwitches
