interface TextTypes {
  textArray: string[]
}

const Text = ({ textArray }: TextTypes) => {
  if (!textArray?.length) {
    return null
  }

  return textArray.map((txt) => (
    <p key={txt} className="pdc-text">
      {txt}
    </p>
  ))
}

export default Text
