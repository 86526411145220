import { QueryClient, UseQueryResult } from "@tanstack/react-query"
import { cloneDeep } from "lodash"

import { useAccountOverrider, useQueryWithRefresh } from "../utils"
import { getDashboardAccounts } from "../../../api/data"
import { UseQueryWithRefreshConfig } from "../utils/useQueryWithRefresh"
import {
  DashboardAccountsTypes,
  ObjectListItemTypes,
} from "@/types/dashboardAccounts"

interface UseDashboardAccountsConfig<TData, TError>
  extends Partial<UseQueryWithRefreshConfig<TData, TError>> {
  staleTime?: number
}

export function useDashboardAccounts<
  Tdata extends DashboardAccountsTypes,
  TError,
>(
  queryClient: QueryClient,
  config: UseDashboardAccountsConfig<Tdata, TError> = {}
) {
  const applyAccountOverride = useAccountOverrider()

  const query: UseQueryResult<Tdata, TError> = useQueryWithRefresh<
    Tdata,
    TError
  >(
    queryClient,
    ["accounts", "dashboard-accounts"],
    getDashboardAccounts,
    config
  )

  const transformedData = query.data ? cloneDeep(query.data) : query.data
  if (transformedData) {
    transformedData?.object_list?.forEach((account: ObjectListItemTypes) => {
      applyAccountOverride(account)
    })
  }

  return {
    ...query,
    data: transformedData,
  }
}
