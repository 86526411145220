interface MembershipBannerTextProps {
  isTimerComplete: boolean
}

const MembershipBannerText = ({
  isTimerComplete,
}: MembershipBannerTextProps) => {
  if (isTimerComplete) {
    return (
      <p className="leading-[140%] tracking-0.32 mt-4">
        Become a member to access the tools and insights you need to achieve
        your goals.
      </p>
    )
  }

  return (
    <p className="leading-[140%] tracking-0.32 mt-4">
      Prices change at the end of the year. Join before January 1st to get 50%
      off your annual membership
    </p>
  )
}

export default MembershipBannerText
