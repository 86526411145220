import CheckmarkListItem from "./CheckmarkListItem"

const PremiumCheckmarkList = () => {
  return (
    <>
      <CheckmarkListItem>Assigned Expert Advisor</CheckmarkListItem>

      <CheckmarkListItem className="mt-3">
        1-on-1 strategy session to understand your goals
      </CheckmarkListItem>

      <CheckmarkListItem className="mt-3">
        Personalized analysis of your land’s potential and local market
        conditions
      </CheckmarkListItem>

      <CheckmarkListItem className="mt-3">
        Documented plan so you know what to do, when to do it, and recommended
        programs to fund your goals
      </CheckmarkListItem>
    </>
  )
}

export default PremiumCheckmarkList
