import {
  faDollarSign,
  faLock,
  faCheck,
  faKey,
} from "@fortawesome/pro-solid-svg-icons"

import Section from "./Section"
import { ContractDetailsTypes } from "@/types/program"

const ContractDetails = ({ data }: { data: ContractDetailsTypes }) => {
  if (!data) {
    return null
  }

  return (
    <>
      <h3 className="text-2xl text-charcoal-900 mb-4.5">Contract details</h3>

      <Section
        title="Earnings and penalties"
        data={data.earnings_and_penalties}
        icon={faDollarSign}
        className="mb-6"
      />

      <Section
        title="Restrictions"
        data={data.restrictions}
        icon={faLock}
        className="mb-6"
      />

      <Section
        title="Landowner rights"
        data={data.landowner_rights}
        icon={faCheck}
        className="mb-6"
      />

      <Section
        title="Property access"
        data={data.property_access}
        icon={faKey}
        className="mb-6"
      />
    </>
  )
}

export default ContractDetails
