import { useCallback } from "react"

import { useProjectsStore } from "../stores/useProjectsStore"
import { ProgramCategoryType } from "@/types/constants"

export const useFiltersOnChange = (handleFirstPageRedirect: () => void) => {
  const { category, setCategory } = useProjectsStore()

  const handleFiltersOnChange = useCallback(
    (filter: { id: string }) => {
      const filterId = filter.id as ProgramCategoryType

      setCategory(filterId === category ? null : filterId)
      handleFirstPageRedirect()
    },
    [category, setCategory, handleFirstPageRedirect]
  )

  return handleFiltersOnChange
}
