import PropertyMap from "../../../sections/LandCoverDetails/PropertyMap"
import LandMapOverlay from "./LandMapOverlay"
import LandMapLegend from "./LandMapLegend"
import { ActiveTabType } from "../Land"
import { useHeapTrackOnView } from "../../../hooks/useHeapTrackOnView"
import { TileURLsTypes } from "@/types/tiles"

interface LandMapProps {
  accountId: string
  isMember: boolean
  activeTab: ActiveTabType
  noDetections: boolean
  dataNotReady: boolean
  changeDetectionData: TileURLsTypes | undefined
  changeDetectionIsLoading: boolean
}

const LandMap = ({
  accountId,
  isMember,
  activeTab,
  noDetections,
  dataNotReady,
  changeDetectionData,
  changeDetectionIsLoading,
}: LandMapProps) => {
  const mapRef = useHeapTrackOnView({
    event: "componentview-dashboard-map",
    properties: { activeTab },
    observerOptions: { threshold: 0.5 },
  })

  return (
    <div ref={mapRef}>
      <PropertyMap
        accountId={accountId}
        overlay={
          <LandMapOverlay
            isMember={isMember}
            show={activeTab === "loss-detection"}
            noDetections={noDetections}
            dataNotReady={dataNotReady}
          />
        }
        legend={<LandMapLegend show={activeTab === "loss-detection"} />}
        changeDetection={activeTab === "loss-detection"}
        changeDetectionData={changeDetectionData}
        changeDetectionIsLoading={changeDetectionIsLoading}
        enableZoomPan
        isWidget
        heapEvent={
          activeTab === "loss-detection"
            ? "loss-detection-map-viewport-changed"
            : undefined
        }
      />
    </div>
  )
}

export default LandMap
