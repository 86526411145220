import { useCallback } from "react"
import { useNavigate } from "react-router"

import { useAccountId, useIsMultiAccount } from "../hooks"

export const useFirstPageRedirect = () => {
  const accountId = useAccountId()
  const isMultiAccount = useIsMultiAccount()
  const navigate = useNavigate()

  const handleFirstPageRedirect = useCallback(() => {
    navigate(
      isMultiAccount
        ? `/accounts/${accountId}/programs/page/1`
        : `/programs/page/1`,
      { replace: true }
    )
  }, [isMultiAccount, accountId, navigate])

  return handleFirstPageRedirect
}
