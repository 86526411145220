import { ReactElement, useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { Formik, FormikValues } from "formik"

import PropertyDetailsFormComponent from "./PropertyDetailsFormComponent"
import { genericErrMsg } from "../../api/auth"
import { useAccountId, useUpdateAccount } from "../../hooks"
import { validationSchema } from "./PropertyDetailsFormHelpers"
import { AccountProperty } from "../../types/property"
import { AccountTypes } from "../../types/account"

interface PropertyDetailsFormTypes {
  initialValues: AccountTypes
  onSuccess: () => void
  backLink: ReactElement<any, any>
  property: AccountProperty
  submitText: string
}

// DEV: Use params to filter out props exclusive to this component (not FormComponent)
const PropertyDetailsForm = ({
  initialValues,
  onSuccess,
  backLink,
  property,
  submitText,
}: PropertyDetailsFormTypes) => {
  const accountId = useAccountId()
  const queryClient = useQueryClient()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  // Coerce any potentially NULL checkbox booleans to true booleans
  if (initialValues?.works_with_consulting_forester === null) {
    initialValues.works_with_consulting_forester = false
  }

  const { mutateAsync: updateAccount } = useUpdateAccount<
    AccountTypes,
    Error,
    FormikValues
  >(queryClient, accountId, {
    onSuccess,
    onError: (error: { detail: string }) => {
      setErrorMessage(error?.detail || genericErrMsg)
    },
  })

  const handleSubmit = async (values: FormikValues) => {
    setErrorMessage(null)

    // Make sure we only send one of property address data or primary property data to
    // bark_api. We do this on submission rather than when the "Does your property have a street address?"
    // option is changed so that a user can go back and forth between "Yes" and "No"
    // without losing any of the data they entered -- we only reset data when they submit.

    await updateAccount(values)
  }

  return (
    <div className="grid grid-cols-1 gap-4 mb-10  md:mb-0 md:order-2 md:w-full md:grid-cols-3">
      <div className="md:order-1 md:col-span-full">
        {/* DEV: Avoid `enableReinitialize=true` as if we set up `window.refetchOnWindowFocus` */}
        {/*   then any form updates will be swept away on window refocus */}
        {/*   Can also be remedied by putting `useProfile` at same level as `Formik` component */}
        {/*   These comments may now be out of date after https://app.asana.com/0/0/1201367694639660/1201368096424394/f */}
        <Formik
          enableReinitialize={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <PropertyDetailsFormComponent
              errorMessage={errorMessage}
              formikProps={formikProps}
              backLink={backLink}
              property={property}
              submitText={submitText}
            />
          )}
        </Formik>
      </div>
    </div>
  )
}

export default PropertyDetailsForm
