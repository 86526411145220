import { Accordion } from "react-accessible-accordion"
import RangeSlider from "../../../components/RangeSlider"
import FilterAccordionItem from "./FilterAccordionItem"
import { PAYMENT_TYPES } from "../../../shared/constants"
import { useProjectsStore } from "../../../stores/useProjectsStore"

interface ProjectFiltersDrawerAccordionProps {
  handleTermLength: (e: [number, number]) => void
  handleFirstPageRedirect: () => void
}

const ProjectFiltersDrawerAccordion = ({
  handleTermLength,
  handleFirstPageRedirect,
}: ProjectFiltersDrawerAccordionProps) => {
  const { termLengthFilter, paymentTypes, setPaymentTypes } = useProjectsStore()

  return (
    <Accordion
      allowMultipleExpanded={true}
      allowZeroExpanded={true}
      className="divide-y mt-6"
    >
      <FilterAccordionItem heading="Term length">
        <RangeSlider
          id="term-length"
          ariaLabel="Term length"
          value={termLengthFilter}
          onChange={(e) => {
            handleTermLength(e)
            handleFirstPageRedirect()
          }}
          lowerInputLabel="Min term length"
          upperInputLabel="Max term length"
        />
      </FilterAccordionItem>

      <FilterAccordionItem heading="Payment terms">
        {Object.entries(PAYMENT_TYPES).map(([key, val]) => (
          <label
            key={key}
            htmlFor={key}
            className="flex flex-row cursor-pointer mb-2"
          >
            <span className="flex mr-2 shrink-0 items-center relative">
              <input
                id={key}
                type="checkbox"
                className="checkbox"
                checked={paymentTypes[key as keyof typeof paymentTypes]}
                onChange={(e) => {
                  setPaymentTypes({
                    ...paymentTypes,
                    [key]: e.target.checked,
                  })
                  handleFirstPageRedirect()
                }}
              />
              <span className="checkmark"></span>
            </span>
            <span>{val}</span>
          </label>
        ))}
      </FilterAccordionItem>
    </Accordion>
  )
}

export default ProjectFiltersDrawerAccordion
