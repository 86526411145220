import { QueryClient, UseMutationResult } from "@tanstack/react-query"

import {
  useMutationWithRefresh,
  UseMutationWithRefreshConfig,
} from "../utils/useMutationWithRefresh"
import { removeAccountUser } from "../../../api/data"
import { AccountUser } from "@/types/accountUsers"

export function useRemoveAccountUser<
  TData extends AccountUser,
  TError,
  TVariables,
>(
  queryClient: QueryClient,
  accountId: string,
  email: string,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables, unknown> {
  return useMutationWithRefresh<TData, TError, TVariables>(
    queryClient,
    () => removeAccountUser(accountId, email),
    {
      _onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["accounts", accountId, "users"],
        })
      },
      ...config,
    }
  )
}
