import { useState, useLayoutEffect, RefObject } from "react"

export const useIsOverflow = (
  ref: RefObject<HTMLElement | null>,
  clientHeight: number,
  callback?: (hasOverflow: boolean) => void
): { isOverflow: boolean; scrollHeight: number } => {
  const [isOverflow, setIsOverflow] = useState<boolean>(false)
  const [scrollHeight, setScrollHeight] = useState<number>(0)

  useLayoutEffect(() => {
    const { current } = ref

    const trigger = () => {
      if (current) {
        const hasOverflow = current.scrollHeight > clientHeight

        setIsOverflow(hasOverflow)
        setScrollHeight(current.scrollHeight)

        if (callback) callback(hasOverflow)
      }
    }

    if (current) {
      trigger()
    }
  }, [clientHeight, callback, ref])

  return { isOverflow, scrollHeight }
}
