import { ReactNode } from "react"
import cx from "classnames"

import useMediaQuery from "../../hooks/useMediaQuery"

interface OnboardingBtnContainerProps {
  children: ReactNode
  errorMessage?: string | null | undefined
}

const OnboardingBtnContainer = ({
  children,
  errorMessage,
}: OnboardingBtnContainerProps) => {
  const isMaxWidthMd = useMediaQuery("(max-width: 767px)")

  return (
    <div
      className={cx("flex flex-col-reverse gap-6 mt-4", {
        "fixed bottom-0 left-0 right-0 bg-white border-t border-dusk-50 px-4 py-6 ":
          isMaxWidthMd,
      })}
    >
      {children}

      {errorMessage && (
        <div className="text-red-500 text-sm">{errorMessage}</div>
      )}
    </div>
  )
}

export default OnboardingBtnContainer
