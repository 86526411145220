import { ReactNode } from "react"
import cx from "classnames"
import { DialogDisclosure, useDialogState } from "reakit/Dialog"
import { Modal } from "./Modal"

interface HelpCardItemTypes {
  title: string
  content: ReactNode
}

interface HelpCardContentTypes {
  items: HelpCardItemTypes[]
}

interface HelpCardTypes {
  items: HelpCardItemTypes[]
  className?: string
}

const HelpCardContent = ({ items }: HelpCardContentTypes) => (
  <div className="space-y-4">
    {items.map((item, i) => (
      <div key={i}>
        <p className="mb-2">
          <strong>{item.title}</strong>
        </p>
        <div>{item.content}</div>
      </div>
    ))}
  </div>
)

const helpCardClassName = "card card-content-inner border-light"

export const HelpCard = ({ items, className }: HelpCardTypes) => {
  const dialog = useDialogState({ animated: true })

  return (
    <>
      {/* DEV: Only have buttons on mobile-first (for a11y) so keeping it simple by using duplicate content */}
      {/* Mobile-first content */}
      <DialogDisclosure
        {...dialog}
        className={cx(helpCardClassName, "w-full md:hidden", className)}
      >
        <div className="flex flex-row">
          <span className="shrink-0 mr-3.5">
            <i className="fas fa-lightbulb-on fa-lg text-leaf"></i>
          </span>
          <div className="space-y-4">
            {items.map((item, i) => (
              <p key={i} className="text-left text-leaf">
                <strong>{item.title}</strong>
              </p>
            ))}
          </div>
        </div>
      </DialogDisclosure>
      <Modal
        header={<i className="fas fa-lightbulb-on fa-lg text-leaf"></i>}
        aria-label="Help dialog"
        dialog={dialog}
      >
        <HelpCardContent items={items} />
      </Modal>

      {/* Medium and larger content */}
      <div className={cx(helpCardClassName, "hidden  md:block", className)}>
        <p className="mb-4">
          <i className="fas fa-lightbulb-on fa-lg text-leaf"></i>
        </p>
        <HelpCardContent items={items} />
      </div>
    </>
  )
}
