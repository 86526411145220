interface HeadingTypes {
  heading: string
}

const Heading = ({ heading }: HeadingTypes) => {
  if (!heading?.length) {
    return null
  }

  return <h5 className="pdc-heading">{heading}</h5>
}

export default Heading
