import { MouseEvent, useCallback, useEffect, useRef } from "react"
import { ScrollMenu, publicApiType } from "react-horizontal-scrolling-menu"

import CategoryFilterItem from "./CategoryFilterItem"
import useDrag from "../../../hooks/useDrag"
import usePreventBodyScroll from "../../../hooks/usePreventBodyScroll"
import { useFiltersOnChange } from "../../../hooks/useFiltersOnChange"
import { onWheel } from "../../../shared/utils"

export interface FilterType {
  id: string
  tag: string
  selected: boolean
  image: string
}

interface CategoryFilterProps {
  filters: FilterType[]
  handleFirstPageRedirect: () => void
}

const CategoryFilter = ({
  filters = [],
  handleFirstPageRedirect,
}: CategoryFilterProps) => {
  const { dragStart, dragStop, dragMove, dragging } = useDrag()
  const { disableScroll, enableScroll } = usePreventBodyScroll()
  const selectedFilter = filters.find((filter) => filter.selected)
  const scrollMenuRef = useRef<publicApiType>(null!)
  const handleFiltersOnChange = useFiltersOnChange(handleFirstPageRedirect)

  const handleDrag = useCallback(
    ({ scrollContainer }: publicApiType) =>
      (ev: MouseEvent) =>
        dragMove(ev, (posDiff: number) => {
          if (scrollContainer.current) {
            scrollContainer.current.scrollLeft += posDiff
          }
        }),
    [dragMove]
  )

  const handleMouseLeave = useCallback(() => {
    enableScroll()
    dragStop()
  }, [enableScroll, dragStop])

  useEffect(() => {
    if (selectedFilter && scrollMenuRef.current) {
      const itemElement = scrollMenuRef.current.getItemElementById(
        selectedFilter.id
      )
      if (itemElement) {
        scrollMenuRef.current.scrollToItem(itemElement, "smooth", "center")
      }
    }
  }, [selectedFilter])

  return (
    <div
      className="category-filter bg-charcoal-600"
      onMouseEnter={disableScroll}
      onMouseLeave={handleMouseLeave}
    >
      <ScrollMenu
        apiRef={scrollMenuRef}
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        onMouseMove={handleDrag}
        onWheel={onWheel}
      >
        {filters.map((filter) => (
          <CategoryFilterItem
            key={filter.id}
            tag={filter.tag}
            image={filter.image}
            selected={filter.selected}
            onChange={() => !dragging && handleFiltersOnChange(filter)}
          />
        ))}
      </ScrollMenu>
    </div>
  )
}

export default CategoryFilter
