import { Link, useParams } from "react-router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons"

import { FEATURE_FLAGS } from "../../shared/constants"
import { useAccountUrlPrefix } from "../../hooks"
import useFeatureFlags from "../../hooks/useFeatureFlags"
import { useHeapTrackOnView } from "../../hooks/useHeapTrackOnView"

interface ProjectDetailsMembershipBannerProps {
  isMember: boolean | undefined
}

const ProjectDetailsMembershipBanner = ({
  isMember,
}: ProjectDetailsMembershipBannerProps) => {
  const accountUrlPrefix = useAccountUrlPrefix()
  const { projectId } = useParams()

  const membershipBannerRef = useHeapTrackOnView({
    event: "componentview-program-membership-banner",
    properties: { program: projectId },
    observerOptions: { threshold: 0.5 },
  })

  const { flagEnabled, clientReady } = useFeatureFlags(
    FEATURE_FLAGS.program_detail_membership_banner_20241121
  )

  if (isMember) {
    return null
  }

  return (
    <div ref={membershipBannerRef} className="w-full py-7 bg-sand">
      <div className="container flex gap-4 px-5 xl:max-w-1120">
        <div className="text-yellow-600 text-2xl">
          <FontAwesomeIcon icon={faTriangleExclamation} />
        </div>

        <div>
          <p className="text-charcoal-500 text-2xl font-bold leading-130 tracking-0.378">
            {clientReady && flagEnabled
              ? "Don't miss out on important program contract information like earnings, penalties, and restrictions for every program."
              : "Attention: Your access to important program details is restricted."}
          </p>

          <p className="text-charcoal-500 text-base leading-130 tracing-0.32 mt-3">
            {clientReady && flagEnabled ? (
              <>
                <Link
                  to={`${accountUrlPrefix}/become-a-member`}
                  className="program-membership-banner-link link"
                  state={{ scrollToTop: true }}
                >
                  Click here
                </Link>{" "}
                to become a member and unlock full program details.
              </>
            ) : (
              <>
                <Link
                  to={`${accountUrlPrefix}/become-a-member`}
                  className="program-membership-banner-link link"
                  state={{ scrollToTop: true }}
                >
                  Become an NCX member
                </Link>{" "}
                to view earnings, penalties, restrictions, and more for every
                program.
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  )
}

export default ProjectDetailsMembershipBanner
