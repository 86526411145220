import { Link } from "react-router"

import { PROJECT_TYPES } from "../../shared/constants"
import { ReactNode } from "react"

interface MessageTypes {
  data?: any
  date: string
  img: string | undefined
  children: ReactNode
}

const Message = ({ data, date, img, children }: MessageTypes) => (
  <div className="flex gap-3 p-6">
    <div>
      <div className="w-12 h-12 flex items-center rounded overflow-hidden">
        {img ? (
          <img
            src={img}
            alt="avatar"
            className="block object-cover w-12 h-12"
          />
        ) : null}
      </div>
    </div>

    <div className="max-w-full-60">
      <p className="text-dusk text-xs font-semibold leading-130">{date}</p>

      <div className="mt-2">{children}</div>

      {data ? (
        <div className="mt-4">
          <p className="text-dusk text-xs leading-130">
            {data?.potential_earnings !== null
              ? `${data?.potential_earnings}/per acre • `
              : null}
            {data?.lo_cost === 0
              ? "No landowner cost"
              : `$${data?.lo_cost}/per acre`}{" "}
            •{" "}
            {data?.term === null
              ? "Term length varies"
              : `${data?.term} year term`}{" "}
            • {PROJECT_TYPES[data?.type as keyof typeof PROJECT_TYPES]?.long}
            <Link
              to={`/programs/${data.project_id}`}
              className="link text-xs leading-130 inline-block ml-2"
            >
              View Details
            </Link>
          </p>
        </div>
      ) : null}
    </div>
  </div>
)

export default Message
