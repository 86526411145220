import { ReactNode, useEffect, useState } from "react"
import { useParams } from "react-router"
import mapboxglSupported from "@mapbox/mapbox-gl-supported"
import { DialogStateReturn } from "reakit/Dialog"
import { FeatureCollection, Geometry, Properties } from "@turf/helpers"

import MapboxGLNotSupported from "../../../components/MapVisualization/MapboxGLNotSupported"
import ProjectMapHeader from "./ProjectMapHeader"
import ProjectMapVisualization from "./ProjectMapVisualization"
import ProjectMapOverlay from "./ProjectMapOverlay"
import ProjectMapParcels from "./ProjectMapParcels"
import ProjectMapSkeleton from "./ProjectMapSkeleton"
import { getViewportFromFeature } from "./helpers"
import { LANDOWNER_STATUS } from "../../../shared/constants"
import { shortenAcreage } from "../../../utils"
import { genericErrMsg } from "../../../api/auth"
import { useHeapTrackOnView } from "../../../hooks/useHeapTrackOnView"
import { TileURLsTypes } from "@/types/tiles"
import { BadgeDisplayType, IneligibilityReasonsType } from "@/types/constants"
import { ViewportTypes } from "@/types"

interface ProjectMapTypes {
  isLoading: boolean
  tileData: TileURLsTypes | undefined
  parcelData: FeatureCollection<Geometry, Properties> | undefined
  dialog: DialogStateReturn
  totalAcreage: number | undefined
  isIneligible: boolean
  ineligibilityReasons: IneligibilityReasonsType[] | null | undefined
  eligibleAcres: number
  badgeDisplay: BadgeDisplayType
  eligibilityDialog: DialogStateReturn
  overlay?: ReactNode
  isMember?: boolean
}

const ProjectMap = ({
  isLoading,
  tileData,
  parcelData,
  dialog,
  totalAcreage,
  isIneligible,
  ineligibilityReasons,
  eligibleAcres,
  badgeDisplay,
  eligibilityDialog,
  overlay,
  isMember,
}: ProjectMapTypes) => {
  const [activeFeature, setActiveFeature] = useState<number | null>(0)
  const [viewport, setViewport] = useState<ViewportTypes | null>(null)
  const { projectId } = useParams()

  const projectMapRef = useHeapTrackOnView({
    event: "componentview-program-eligibility-map",
    properties: { program: projectId },
    observerOptions: { threshold: 0.5 },
  })

  const multipleParcels =
    Array.isArray(parcelData?.features) && parcelData?.features.length > 1

  useEffect(() => {
    if (parcelData !== undefined) {
      setViewport(getViewportFromFeature(parcelData?.features[0]))
    }
  }, [parcelData])

  if (!mapboxglSupported.supported()) {
    const reason = mapboxglSupported.notSupportedReason()

    return (
      <div className="container xl:max-w-1120 mt-[66px]">
        <div className="lg:max-w-[844px] h-[400px] lg:flex relative rounded bg-gray2 overflow-hidden p-6">
          <MapboxGLNotSupported reason={reason} />
        </div>
      </div>
    )
  }

  return (
    <div
      ref={projectMapRef}
      className="container xl:max-w-1120 mt-[66px] px-5 lg:px-16"
    >
      <ProjectMapHeader dialog={dialog} />

      <div className="lg:max-w-[844px] lg:flex relative rounded overflow-hidden">
        {isLoading ? (
          <ProjectMapSkeleton animate />
        ) : tileData && parcelData ? (
          <>
            <ProjectMapVisualization
              viewport={viewport}
              setViewport={setViewport}
              parcelData={parcelData}
              tileData={tileData}
              activeFeature={activeFeature}
              isIneligible={isIneligible}
              layerSwitchText="Eligibility"
              overlay={overlay}
              isMember={isMember}
            />
            {isMember !== false && multipleParcels && (
              <ProjectMapParcels
                isIneligible={isIneligible}
                parcelData={parcelData}
                activeFeature={activeFeature}
                setActiveFeature={setActiveFeature}
                setViewport={setViewport}
                className="lo-project-details"
              />
            )}

            {isIneligible ? (
              <ProjectMapOverlay
                title={
                  ineligibilityReasons && ineligibilityReasons.length > 0
                    ? ineligibilityReasons[0].charAt(0).toUpperCase() +
                      ineligibilityReasons[0].slice(1)
                    : "You are ineligible"
                }
                text={`${
                  typeof eligibleAcres === "number"
                    ? shortenAcreage(eligibleAcres)
                    : 0
                }/${shortenAcreage(
                  totalAcreage || 0
                )} acres eligible for this program`}
              />
            ) : null}

            {!isIneligible && totalAcreage && totalAcreage >= 20000 ? (
              <ProjectMapOverlay
                title="Wow! You've got a lot of land"
                text="Let's talk about your eligibility."
                ctaUrl="mailto:landowners@ncx.com"
                ctaText="Get in Touch"
              />
            ) : null}

            {isMember !== false &&
            badgeDisplay === LANDOWNER_STATUS.information_needed ? (
              <ProjectMapOverlay
                title="Discover your eligibility"
                text="Answer questions about your land to unlock eligibility."
                ctaType="button"
                ctaText="Check Eligibility"
                ctaOnClick={eligibilityDialog.show}
              />
            ) : null}
          </>
        ) : (
          <div className="relative w-full">
            <ProjectMapSkeleton
              message={
                <div className="lg:max-w-[844px] h-[400px] lg:flex relative rounded overflow-hidden p-6">
                  <div className="w-full h-full">
                    <div className="w-[calc(100% - 30px)] md:w-3/4 lg:w-1/2 mx-auto mt-1/4 bg-white p-12">
                      {genericErrMsg}
                    </div>
                  </div>
                </div>
              }
            />

            {overlay}
          </div>
        )}
      </div>
    </div>
  )
}

export default ProjectMap
