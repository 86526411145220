import ToggleSwitch from "../../components/ToggleSwitch"
import { useProjectsStore } from "../../stores/useProjectsStore"

interface IneligibleProgramsToggleSwitchProps {
  handleFirstPageRedirect: () => void
}

const IneligibleProgramsToggleSwitch = ({
  handleFirstPageRedirect,
}: IneligibleProgramsToggleSwitchProps) => {
  const { ineligibleProjectsOn, setIneligibleProjectsOn } = useProjectsStore()

  return (
    <div className="hidden xl:flex p-1.75 border border-dusk-50 rounded order-2">
      <ToggleSwitch
        label="Ineligible programs"
        value={ineligibleProjectsOn}
        onChange={() => {
          setIneligibleProjectsOn(!ineligibleProjectsOn)
          handleFirstPageRedirect()
        }}
      />
    </div>
  )
}

export default IneligibleProgramsToggleSwitch
