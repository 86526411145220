import { Dispatch, SetStateAction } from "react"
import { AccountTypes } from "../../types/account"
import { DialogDisclosure, DialogInitialState } from "reakit"
import { RowDataTypes } from "../../components/Table"
import { ActionPermissionWrapper } from "../../components/ActionPermissionWrapper"
import { ToolTip } from "../../components/ToolTip"

interface UserColumnsTypes {
  account: AccountTypes
  accountId?: string
  setEditingModel: Dispatch<SetStateAction<RowDataTypes | null>>
  userDialog: DialogInitialState
}

export const getUserColumns = ({
  account,
  setEditingModel,
  userDialog,
}: UserColumnsTypes) => ({
  name: {
    title: "Name",
    className: "border-b font-semibold",
    getValue: ({
      first_name = "",
      last_name = "",
      email,
      is_requester,
      account_user_type,
    }: RowDataTypes) => (
      <span>
        {`${first_name} ${last_name}`.trim() || email}{" "}
        {is_requester && (
          <span className="text-dusk font-normal text-sm">(you)</span>
        )}
        {account_user_type === "invite" && (
          <span className="text-dusk font-normal text-sm">
            (invitation pending) {""}
            <ToolTip
              aria-label={
                "User must accept email invitation to gain account access"
              }
            >
              {"User must accept email invitation to gain account access"}
            </ToolTip>
          </span>
        )}
      </span>
    ),
  },
  role: {
    title: "Role",
    className: "border-b capitalize",
    getValue: (model: RowDataTypes) => model.role,
  },
  actions: {
    className: "!px-2 w-px border-b font-semibold",
    getValue: (model: RowDataTypes) => (
      <ActionPermissionWrapper
        accountRole={account.role}
        // Also see `updateDisabled` and `deleteDisabled` lines further down
        action={model.is_requester ? "editUserSelf" : "editUser"}
      >
        <DialogDisclosure
          {...userDialog}
          className="btn2 btn2-plain px-1"
          onClick={() => setEditingModel(model)}
        >
          Edit
        </DialogDisclosure>
      </ActionPermissionWrapper>
    ),
  },
})
