import { RefObject } from "react"
import cx from "classnames"

import ProjectFiltersDrawerHeader from "./ProjectFiltersDrawerHeader"
import ProjectFiltersDrawerFooter from "./ProjectFiltersDrawerFooter"
import ProjectFiltersDrawerAccordion from "./ProjectFiltersDrawerAccordion"
import ProjectFiltersDrawerFilterSwitches from "./ProjectFiltersDrawerFilterSwitches"

interface ProjectFiltersDrawerProps {
  overlayRef: RefObject<HTMLDivElement | null>
  filtersRef: RefObject<HTMLDivElement | null>
  filtersOpen: boolean
  handleClose: () => void
  handleFirstPageRedirect: () => void
  handleTermLength: (e: [number, number]) => void
  filteredNumber: number
}

const ProjectFiltersDrawer = ({
  overlayRef,
  filtersRef,
  filtersOpen,
  handleClose,
  handleFirstPageRedirect,
  handleTermLength,
  filteredNumber,
}: ProjectFiltersDrawerProps) => (
  <div
    ref={overlayRef}
    className={cx("project-filters-overlay", {
      "project-filters-overlay-active": filtersOpen,
    })}
    onClick={(e) => {
      if (e.target === overlayRef.current) {
        handleClose()
      }
    }}
  >
    <div
      ref={filtersRef}
      className={cx("project-filters-inner", {
        "project-filters-inner-active": filtersOpen,
      })}
      aria-label="Filters"
    >
      <ProjectFiltersDrawerHeader
        handleFirstPageRedirect={handleFirstPageRedirect}
        handleClose={handleClose}
      />

      <div className="project-filters-inner-content">
        <ProjectFiltersDrawerFilterSwitches
          handleFirstPageRedirect={handleFirstPageRedirect}
        />

        <ProjectFiltersDrawerAccordion
          handleTermLength={handleTermLength}
          handleFirstPageRedirect={handleFirstPageRedirect}
        />
      </div>

      <ProjectFiltersDrawerFooter
        handleClose={handleClose}
        filteredNumber={filteredNumber}
      />
    </div>
  </div>
)

export default ProjectFiltersDrawer
