import { Link } from "react-router"

import MessagePreview from "./MessagePreview"
import { LANDOWNER_STATUS } from "../../shared/constants"
import { useAccountId, useIsMultiAccount } from "../../hooks"
import { NotificationTypes } from "../../types/notifications"

interface InvitationsBannerTypes {
  invitesData: NotificationTypes[] | undefined
}

const InvitationsBanner = ({ invitesData = [] }: InvitationsBannerTypes) => {
  const accountId = useAccountId()
  const isMultiAccount = useIsMultiAccount()

  invitesData = invitesData?.filter(
    (invite: NotificationTypes) =>
      invite?.extra_fields &&
      "landowner_status" in invite.extra_fields &&
      invite.extra_fields.landowner_status !==
        LANDOWNER_STATUS.request_information &&
      invite.extra_fields.landowner_status !==
        LANDOWNER_STATUS.under_contract &&
      invite.extra_fields.landowner_status !==
        LANDOWNER_STATUS.not_interested &&
      invite.extra_fields.landowner_status !==
        LANDOWNER_STATUS.lo_not_interested &&
      invite.extra_fields.landowner_status !==
        LANDOWNER_STATUS.datapack_shareable
  )

  if (invitesData.length === 0) {
    return (
      <div className="container max-w-6xl my-6 md:mt-0 rounded">
        <div className="flex flex-col xl:flex-row justify-between xl:items-center bg-sand rounded p-4 xl:px-12 xl:py-6">
          <p className="text-charcoal-500 text-lg font-bold leading-130 tracking-0.378">
            No Invites Yet
          </p>

          <p className="text-charcoal-500 text-base leading-130 tracing-0.32 xl:ml-4 xl:mr-auto mt-4 xl:mt-0">
            Look out for exclusive invites from program partners interested in
            your land.
          </p>

          <Link
            to={isMultiAccount ? `/accounts/${accountId}/inbox` : "/inbox"}
            className="btn2 btn2-outline-primary text-center bg-white font-bold md:self-start xl:ml-auto mr-0 mt-3 xl:mt-0"
          >
            View Inbox
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div className="container max-w-6xl mb-14 mt-6 md:mt-0">
      <div className="bg-sand flex justify-between rounded p-4 lg:px-12 lg:py-6">
        <div className="self-center w-full lg:w-1/2 lg:pr-[65px]">
          <p className="text-dusk-500 text-sm font-bold leading-120 tracking-[0.84px] uppercase">
            Just for you
          </p>

          <h3 className="text-charcoal-500 text-lg font-semibold leading-130 tracking-0.378 mt-2">
            Exclusive Invites Waiting
          </h3>

          <p className="max-w-[500px] text-charcoal-500 text-base leading-130 tracking-0.32 mt-2">
            You have{" "}
            <strong className="font-bold">
              {invitesData?.length} pending{" "}
              {invitesData?.length === 1 ? "invitation" : "invitations"}
            </strong>{" "}
            to connect from program partners who want to learn about your land
            goals.
          </p>

          <Link
            to={isMultiAccount ? `/accounts/${accountId}/inbox` : "/inbox"}
            className="btn2 btn2-primary btn2-block md:btn2-inline-block font-bold mt-6"
          >
            View Invitations
          </Link>
        </div>

        <div className="w-1/2 hidden lg:flex flex-col gap-4">
          {invitesData
            ?.slice(0, 2)
            ?.map((invite: NotificationTypes) => (
              <MessagePreview key={invite.id} invite={invite} />
            ))}
        </div>
      </div>
    </div>
  )
}

export default InvitationsBanner
