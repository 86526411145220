import { useParams } from "react-router"

import ExpandableContainer from "../../../components/ExpandableContainer"
import ProjectOverview from "./ProjectOverview"
import { useAccountUrlPrefix } from "../../../hooks"
import { useHeapTrackOnView } from "../../../hooks/useHeapTrackOnView"
import { ContractDetailsTypes, OverviewInformationTypes } from "@/types/program"
import ContractDetails from "./ContractDetails"

interface ProjectDetailsContentTypes {
  overviewInformation: OverviewInformationTypes
  contractDetails: ContractDetailsTypes
  isMember: boolean | undefined
  isGovernmentProgram: boolean | undefined
  isSponsored: boolean | undefined
  isInvited: boolean
  isVerified: boolean | undefined
  ctaOverrideUrl?: string | null | undefined
}

const ProjectDetailsContent = ({
  overviewInformation,
  contractDetails,
  isMember,
  isGovernmentProgram,
  isSponsored,
  isInvited,
  isVerified,
  ctaOverrideUrl,
}: ProjectDetailsContentTypes) => {
  const { projectId } = useParams()
  const accountUrlPrefix = useAccountUrlPrefix()

  const programOverviewRef = useHeapTrackOnView({
    event: "componentview-program-overview",
    properties: { program: projectId },
    observerOptions: { threshold: 0.5 },
  })

  if (!overviewInformation && !contractDetails) {
    return null
  }

  return (
    <div ref={programOverviewRef} className="w-full lg:max-w-552">
      <ExpandableContainer
        id={projectId as string}
        btnTextOpen="View earnings, penalties, and restrictions"
        btnTextClose="Hide earnings, penalties, and restrictions"
        className="py-2 lg:p-3 w-full"
        overrideUrl={
          isMember || isGovernmentProgram || isSponsored || isInvited
            ? undefined
            : `${accountUrlPrefix}/become-a-member`
        }
      >
        <ProjectOverview
          data={overviewInformation}
          isVerified={isVerified}
          ctaOverrideUrl={ctaOverrideUrl}
        />

        <ContractDetails data={contractDetails} />
      </ExpandableContainer>
    </div>
  )
}

export default ProjectDetailsContent
