import { privateIndividualFamilyForestOwnerDB } from "../../fixtures/index"

export const OWNERSHIP_TYPE_OPTIONS = [
  {
    value: privateIndividualFamilyForestOwnerDB,
    label: "Private - Individual/Family Forest Owner",
    popoverContent: () => (
      <>
        <div className="font-semibold mb-1">
          Private - Individual/Family Forest Owner
        </div>
        <div>
          A landowner who performs periodic management and whose primary purpose
          for ownership may or may not be as a source of income derived from
          harvesting operations. Ownerships may be held by an individual or a
          family, but also other legal entities such as estates and trusts.
        </div>
      </>
    ),
  },
  {
    value: "Private-Commercial/Industrial",
    label: "Private - Commercial/Industrial",
    popoverContent: () => (
      <>
        <div className="font-semibold mb-1">
          Private - Commercial/Industrial
        </div>
        <div>
          A landowner representing a commercial entity (corporation, LLC, etc)
          who owns the property primarily as a source of income derived from
          harvesting operations and performs frequent management activities
          (annual, or nearly so).
        </div>
      </>
    ),
  },
  {
    value: "Private-Other",
    label: "Private - Other",
    popoverContent: () => (
      <>
        <div className="font-semibold mb-1">Private - Other</div>
        <div>
          An institutional landowner who does not own the property primarily as
          a source of income derived from harvesting operations. Examples
          include conservation groups and hunting clubs.
        </div>
      </>
    ),
  },
  {
    value: "Government-State/Local",
    label: "Government - State/Local",
    popoverContent: () => (
      <>
        <div className="font-semibold mb-1">Government - State/Local</div>
        <div>
          A landowner representing state or local ownership, including
          universities and schools
        </div>
      </>
    ),
  },
  {
    value: "Government-Federal",
    label: "Government - Federal",
    popoverContent: () => (
      <>
        <div className="font-semibold mb-1">Government - Federal</div>
        <div>A landowner representing federal ownerships</div>
      </>
    ),
  },
  {
    value: "Tribal",
    label: "Tribal",
    popoverContent: () => (
      <>
        <div className="font-semibold mb-1">Tribal</div>
        <div>
          A landowner representing a tribal organization. (possibly include:
          Indigenous landowners enrolling their individual properties should
          select from among the Private landowner categories.)
        </div>
      </>
    ),
  },
]
