import {
  faFileContract,
  faClock,
  faCoins,
  faClipboardList,
} from "@fortawesome/pro-solid-svg-icons"

import UnverifiedBanner from "../UnverifiedBanner"
import Section from "./Section"
import { OverviewInformationTypes } from "@/types/program"

interface ProjectOverviewTypes {
  data: OverviewInformationTypes
  isVerified: boolean | undefined
  ctaOverrideUrl?: string | null | undefined
}

const ProjectOverview = ({
  data,
  isVerified,
  ctaOverrideUrl,
}: ProjectOverviewTypes) => {
  if (!data) {
    return null
  }

  return (
    <>
      <h3 className="text-2xl lg:text-3xl mb-5 lg:mb-4.5">Program overview</h3>

      <UnverifiedBanner
        ctaOverrideUrl={ctaOverrideUrl}
        isVerified={isVerified}
      />

      <Section
        title="Key obligation"
        data={data.key_obligation}
        icon={faFileContract}
        className="mb-6"
      />

      <Section
        title="Term length"
        data={data.term_length}
        icon={faClock}
        className="mb-6"
      />

      <Section
        title="Landowner costs"
        data={data.landowner_costs}
        icon={faCoins}
        className="mb-6"
      />

      <Section
        title="Eligibility requirements"
        data={data.eligibility_requirements}
        icon={faClipboardList}
        className="mb-12"
      />
    </>
  )
}

export default ProjectOverview
