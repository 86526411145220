import { QueryClient, UseMutationResult } from "@tanstack/react-query"
import { FormikValues } from "formik"

import { useMutationWithRefresh } from "../utils"
import { createAccount } from "../../../api/data"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"

export function useCreateAccount<
  TData,
  TError,
  TVariables extends FormikValues,
>(
  queryClient: QueryClient,
  accountId: string,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables, unknown> {
  return useMutationWithRefresh<TData, TError, TVariables>(
    queryClient,
    (values: TVariables) => createAccount(values),
    {
      _onSuccess: (data: TData) => {
        // Wipe out all our list data
        queryClient.invalidateQueries({
          queryKey: ["accounts", "dashboard-accounts"],
        })

        // Fill in our known key
        // DEV: This won't fill in magic keys like `_single`
        //   but we don't anticipate `createAccount` being called in the context of magic keys
        queryClient.setQueryData(["accounts", accountId], data)
      },
      ...config,
    }
  )
}
