import { NotificationStatusType } from "@/types/notifications"
import { Link } from "react-router"

interface ThreadNotificationTypes {
  status: NotificationStatusType | null
  url: string
}

const ThreadNotification = ({ status, url }: ThreadNotificationTypes) => {
  const notification = {
    accepted: (
      <p className="text-base leading-130 tracking-0.32 border rounded p-4 border-grass-500 bg-grass-50 m-6 mb-0">
        You accepted this invitation to connect.{" "}
        <Link to={url} className="link">
          View program details
        </Link>
      </p>
    ),
    declined: (
      <p className="text-base leading-130 tracking-0.32 border rounded border-[#ECA674] bg-orange-50 p-4 m-6 mb-0">
        <span className="font-bold">
          You declined this invitation to connect.
        </span>{" "}
        Changed your mind?{" "}
        <Link to={url} className="link">
          View program details
        </Link>
      </p>
    ),
  }

  return notification[status as keyof typeof notification] || null
}

export default ThreadNotification
