import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilter } from "@fortawesome/pro-solid-svg-icons"

interface FiltersOpenProps {
  onClick: () => void
  activeFiltersNum: number
}

const FiltersOpen = ({ onClick, activeFiltersNum }: FiltersOpenProps) => {
  return (
    <button
      type="button"
      className="project-filters-toggle"
      onClick={onClick}
      aria-label="Open filters"
    >
      Filters
      <span className="inline-block ml-1">
        <FontAwesomeIcon icon={faFilter} />
      </span>
      {activeFiltersNum > 0 && (
        <span className="project-filters-toggle-active-num">
          {activeFiltersNum}
        </span>
      )}
    </button>
  )
}

export default FiltersOpen
