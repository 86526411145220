import { Link } from "react-router"
import cx from "classnames"

import { ContentCard } from "../../../components/Card"
import HelperCard from "../../../components/HelperCard"
import NaturalCapitalEstimatorChart from "./NaturalCapitalEstimatorChart"
import { INDEX_DATA_NAMES } from "./constants"
import { useAccountUrlPrefix } from "../../../hooks"
import { IndexDataType } from "@/types/property"

interface NaturalCapitalEstimatorTypes {
  show: boolean
  indexData: IndexDataType
  hasPurchasedNCAReport: boolean
  isMember: boolean
}

const NaturalCapitalEstimator = ({
  show = false,
  indexData,
  hasPurchasedNCAReport = false,
  isMember,
}: NaturalCapitalEstimatorTypes) => {
  const accountUrlPrefix = useAccountUrlPrefix()

  if (!show) {
    return null
  }

  return (
    <ContentCard>
      <h4 className="text-overline">
        Natural Capital Estimator{" "}
        <span className="inline-block text-autumn italic">[Early Access]</span>
      </h4>

      <p className="text-charcoal-500 text-base leading-130 tracking-0.32 mt-2">
        Explore your land's potential. Select a practice to see how your land's
        natural capital may be impacted.
      </p>

      {!hasPurchasedNCAReport ? (
        <p className="text-charcoal-500 text-base leading-130 tracking-0.32 mt-2">
          Ready to take the next step?{" "}
          <Link
            to={`${accountUrlPrefix}/${
              isMember ? "advisor-signup" : "become-a-member"
            }`}
            className="link"
            state={{ includeAdvisor: true }}
          >
            Learn more
          </Link>{" "}
          about how NCX can help.
        </p>
      ) : null}

      <div className="flex gap-2 sm:gap-3 justify-center mt-6">
        {Object.keys(indexData).map((item) => (
          <p
            key={item}
            className={cx(
              "text-xs sm:text-sm",
              {
                "text-autumn": item === "timber_index",
              },
              { "text-[#107C42]": item === "forest_health_index" },
              { "text-[#3B30A3]": item === "carbon_index" }
            )}
          >
            <span
              className={cx(
                "inline-block w-2 h-2 sm:w-3 sm:h-3",
                {
                  "bg-autumn": item === "timber_index",
                },
                { "bg-[#107C42]": item === "forest_health_index" },
                { "bg-[#3B30A3]": item === "carbon_index" }
              )}
            />{" "}
            {INDEX_DATA_NAMES[item as keyof typeof INDEX_DATA_NAMES]}
          </p>
        ))}
      </div>

      <NaturalCapitalEstimatorChart indexData={indexData} />

      <HelperCard className="mt-4">
        This is an experimental feature. If you don't think this looks right for
        your land or have other feedback, please help us improve by sending us
        an email at{" "}
        <a href="mailto:support@ncx.com" className="link">
          landowners@ncx.com
        </a>
        .
      </HelperCard>
    </ContentCard>
  )
}

export default NaturalCapitalEstimator
