const Skeleton = () => (
  <div
    className="bg-cloud-50 overflow-hidden"
    style={{ height: "calc(100vh - 64px)" }}
  >
    <div className="bg-dusk-200 py-6 md:py-12">
      <div className="container text-cloud-50">
        <div
          className="bg-dusk-300 mb-3 w-full h-14"
          style={{ maxWidth: 254 }}
        />
        <div
          className="bg-dusk-300 w-full h-16 md:h-6"
          style={{ maxWidth: 948 }}
        />
      </div>
    </div>

    <div className="container max-w-6xl mb-12">
      <div className="flex justify-end pt-4 pb-6 md:pt-6 md:pb-8">
        <div
          className="bg-dusk-200 h-10 w-full rounded border border-dusk-100"
          style={{ maxWidth: 198 }}
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((card) => (
          <div
            key={card}
            className="rounded bg-dusk-200 aspect-[1/1] lg:aspect-[2/3] xl:aspect-[3/2]"
          />
        ))}
      </div>
    </div>
  </div>
)

export default Skeleton
