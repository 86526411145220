import { QueryClient, UseMutationResult } from "@tanstack/react-query"
import { FormikValues } from "formik"

import { useMutationWithRefresh } from "../utils"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"
import { addAccountUser } from "../../../api/data"
import { AccountUser } from "@/types/accountUsers"

export function useAddAccountUser<
  TData extends AccountUser,
  TError,
  TVariables extends FormikValues,
>(
  queryClient: QueryClient,
  accountId: string,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables, unknown> {
  return useMutationWithRefresh<TData, TError, TVariables>(
    queryClient,
    (values: TVariables) => addAccountUser(accountId, values),
    {
      _onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["accounts", accountId, "users"],
        })
      },
      ...config,
    }
  )
}
