import { QueryClient, UseMutationResult } from "@tanstack/react-query"
import { FormikValues } from "formik"

import { useMutationWithRefresh } from "../utils"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"
import { updateAttestations } from "../../../api/data"
import { EligibilityQuizCategoryType } from "@/types/constants"

export const useUpdateAttestationQuiz = <
  TData,
  TError,
  TVariables extends FormikValues,
>(
  queryClient: QueryClient,
  accountId: string,
  quizCategory: EligibilityQuizCategoryType,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables> => {
  return useMutationWithRefresh(
    queryClient,
    (values: TVariables) => updateAttestations(accountId, values),
    {
      _onSuccess: (data) => {
        queryClient.setQueryData(
          ["accounts", accountId, "attestationQuizes"],
          (oldData: any) => {
            return {
              ...oldData,
              ...data,
            }
          }
        )
        queryClient.invalidateQueries({
          queryKey: ["accounts", accountId, "attestationQuiz", quizCategory],
        })
        queryClient.invalidateQueries({
          queryKey: ["accounts", accountId, "projects"],
        })
        queryClient.invalidateQueries({
          queryKey: ["accounts", "dashboard-accounts"],
        })
        queryClient.invalidateQueries({ queryKey: ["accounts", accountId] })
      },
      ...config,
    }
  )
}
