import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router"

import OnboardingSurveyHeader from "./OnboardingSurveyHeader"
import { useAccountUrlPrefix } from "../../hooks"
import BLOB from "../../images/blob.png"
import DEER from "../../images/deer.gif"

const OnboardingReportLoading = () => {
  const [loadingStep, setLoadingStep] = useState(0)
  const navigate = useNavigate()
  const accountUrlPrefix = useAccountUrlPrefix()

  const steps = [
    "Counting your trees",
    "Scouting for water",
    "Petting the cows",
    "Writing report",
    "Creating recommendations",
  ]
  const intervalId = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    intervalId.current = setInterval(() => {
      if (loadingStep === steps.length - 1) {
        intervalId.current && clearInterval(intervalId.current)
        navigate(`${accountUrlPrefix}/become-a-member`)
      } else {
        setLoadingStep((loadingStep) => loadingStep + 1)
      }
    }, 2000)

    return () => {
      intervalId.current && clearInterval(intervalId.current)
    }
  }, [loadingStep, steps.length, navigate, accountUrlPrefix])

  return (
    <>
      <OnboardingSurveyHeader
        text="Your dashboard is being prepared! We just need to plant a few more seeds."
        headerImg={
          <div className="relative w-24 sm:w-28 md:w-[184px] h-auto block">
            <img src={BLOB} alt="" />
            <img src={DEER} alt="deer" className="error-404-deer" />
          </div>
        }
      />

      <div className="mt-8">
        {steps.map((step, index) => (
          <div key={index} className="flex items-center space-x-2 py-4 px-2">
            {loadingStep >= index ? (
              <>
                <i className="fas fa-check text-green-700" />
                <p className="text-neutral-800 text-lg font-normal">{step}</p>
              </>
            ) : (
              <>
                <i className="fas fa-lg fa-spinner-third fa-spin text-green-700 opacity-40" />
                <p className="text-neutral-800 opacity-40 text-lg font-normal">{`${step}...`}</p>
              </>
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default OnboardingReportLoading
