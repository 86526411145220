import { RefObject, useEffect } from "react"
import MapGL, { Source, Layer } from "@urbica/react-map-gl"
import mapVisualizationDrawStyles from "../shared/mapVisualizationDrawStyles"

interface ParcelSelectModeTypes {
  changeMode?: any
  hoveredParcelId?: any
  map?: any
  onSetup: (opts: any) => Record<string, unknown>
  onStop: () => void
  onClick: (opts: any, e: any) => void
  onMouseOut: (opts: any, e: any) => void
  onKeyUp: (opts: any, e: any) => void
  toDisplayFeatures: (opts: any, geojson: any, display: any) => void
  onTap?: (opts: any, e: any) => void
}

interface ParcelSelectLayerTypes {
  mapRef: RefObject<MapGL>
  layer: string // Adjust type based on your actual data
  features: any[] // Adjust type based on your actual data
}

export const PARCEL_LAYER_MIN_ZOOM = 14

const VECTOR_URL =
  "https://reportallusa.com/api/rest_services/client=" +
  import.meta.env.VITE_APP_REPORTALL_CLIENT_KEY +
  "/ParcelsVectorTile/MapBoxVectorTileServer/tile/{z}/{x}/{y}.mvt"

// DEV: All our singleton globals
//   We could place these in `ParcelSelectMode` (thus `this.hoveredParcelId`)
//   but that's also a singleton so not much benefit
let hoveredFeature: any = null
let hoverListener: ((e: any) => void) | null = null
let leaveListener: ((e: any) => void) | null = null
let addedParcelIds: Set<number> | null = null
let hoveredAndClickedParcelId: number | null = null
let onSuccessCallbacks: (() => void)[] = []

// https://github.com/mapbox/mapbox-gl-draw/blob/v1.2.0/docs/MODES.md
// eslint-disable-next-line react-refresh/only-export-components
export const ParcelSelectMode: ParcelSelectModeTypes = {
  onSetup: function (opts: { reactState?: { addedParcelIds: number[] } }) {
    // When the mode starts this function will be called.
    // The `opts` argument comes from `draw.changeMode(mode, opts)`
    // The `onSetup` return value should be an object and will be passed to all other lifecycle functions
    // DEV: `opts` may be entirely empty due to being invoked by `map.addControl` at mount
    //   e.g. Click "Select parcels" before the map finishes loading fully
    //   https://app.asana.com/0/1199976942355619/1200002265510578/f
    //   https://app.asana.com/0/1199976942355619/1201326814867146/f

    const map = this.map

    let hoveredParcelId = this.hoveredParcelId

    // Restore and persist from `opts` (our way of persisting for this current <MapVisualization>)
    // DEV: `addedParcelIds` will be undefined on initial load
    // DEV: We use `new Set` on `Set || Array` to guarantee we don't modify source data
    // DEV: If we run into browser support issues for `Set`, use an object (arrays lead to sparse array issues)
    addedParcelIds = new Set(opts.reactState?.addedParcelIds || [])

    // Restore all our added features (so hover works as expected)
    addedParcelIds.forEach((parcelId) => {
      map.setFeatureState(
        {
          source: "parcels",
          sourceLayer: "parcels",
          id: parcelId,
        },
        { added: true }
      )
    })

    hoverListener = (e) => {
      map.getCanvas().style.cursor = "pointer"
      if (e.features?.length > 0) {
        if (hoveredParcelId) {
          map.setFeatureState(
            {
              source: "parcels",
              sourceLayer: "parcels",
              id: hoveredParcelId,
            },
            { hover: false }
          )
        }

        hoveredFeature = e.features[0]
        hoveredParcelId = hoveredFeature.id
        // If we had (a parcel that we clicked on and we're still hovering it) (hoveredAndClickedParcelId)
        if (
          hoveredAndClickedParcelId &&
          hoveredParcelId === hoveredAndClickedParcelId
        ) {
          // Do nothing -- we could explain the opposite but this is clearer
          // DEV: Without this check, we'd be drawing "delete" fills on just clicked "added" parcels
          // Otherwise (no parcel clicked on, no longer hovering it)
        } else {
          // Erase our tracking now that it has changed
          hoveredAndClickedParcelId = null

          // Update map with feature we're currently hovering
          map.setFeatureState(
            {
              source: "parcels",
              sourceLayer: "parcels",
              id: hoveredParcelId,
            },
            { hover: true }
          )
        }
      }
    }
    map.on("mousemove", "parcels-fill", hoverListener)

    leaveListener = () => {
      map.getCanvas().style.cursor = ""
    }
    map.on("mouseleave", "parcels-fill", leaveListener)

    return {}
  },

  onStop: function () {
    // Perform our normal cleanup
    this.map.off("mousemove", "parcels-fill", hoverListener)
    this.map.off("mouseleave", "parcels-fill", leaveListener)
    hoverListener = null // Dereference hoverListener to minimize closured memory leaks
    leaveListener = null
    addedParcelIds = null
    onSuccessCallbacks = []
  },

  onClick: function (_opts, e) {
    const map = this.map
    const clickedFeature = map.queryRenderedFeatures(e.point)[0]

    if (clickedFeature && clickedFeature.sourceLayer === "parcels") {
      const clickedParcelId: number = clickedFeature.id

      const added = addedParcelIds?.has(clickedParcelId)

      let action: "add" | "delete" | null = null
      let _onSuccess
      map.setFeatureState(clickedFeature, {
        adding: false,
        added: false,
        removing: false,
        removed: false,
        hover: false, // Wipe out hover state so we don't immediately re-render with delete coloring
      })
      if (added) {
        action = "delete"
        addedParcelIds?.delete(clickedParcelId)
        map.setFeatureState(clickedFeature, { removing: true })
        _onSuccess = () => {
          // If the feature is still in the state we left it, then complete marking it as "removed" for rendering
          // For detailed explanation, see add's onSuccess explanation
          if (map.style && map.getFeatureState(clickedFeature).removing) {
            map.setFeatureState(clickedFeature, {
              removing: false,
              removed: true,
            })
          }
        }
      } else {
        action = "add"
        addedParcelIds?.add(clickedParcelId)
        // DEV: We could build `adding` as `added + loading` but discrete states get more confusing
        //   but it also helps with detecting `loading` for `added` vs `loading` for removed` as per `onSuccess` below
        map.setFeatureState(clickedFeature, { adding: true })
        _onSuccess = () => {
          // If the feature is still in the state we left it, then complete marking it as "added" for rendering
          // DEV: There's a chance we've removed after adding, but before the callback invoked (e.g. slow parcel lookup)
          //   This is our poor solution for effectively cancelling the callback
          //   How to easily reproduce:
          //   - Change `handleFeatureSelectionChange` to not talk to API
          //       and call `data.onSuccess()` via `setTimeout(() => { data.onSuccess() }, 3000)`
          //   - Load page, click on a feature to add (should be solid blue now)
          //   - Hover off/on to see it go to remove state (should preview red)
          //   - Click again before 3s (now empty)
          //   - Wait for 3s, see it change colors unexpectedly (now red -- should be empty)
          // DEV: We check for `map.style` due to `map.getFeatureState` (which runs `map.style.getFeatureState`)
          //   not always resolving properly (possibly map unmounted)
          //   https://app.asana.com/0/1199976942355619/1201324601261014/f
          if (map.style && map.getFeatureState(clickedFeature).adding) {
            map.setFeatureState(clickedFeature, {
              adding: false,
              added: true,
            })
          }
        }
      }

      // Reuse MapboxGL-Draw's custom event listeners for our custom mode
      //   https://github.com/mapbox/mapbox-gl-draw/blob/3de63e20443192168c5994da51878cb418f17ec5/src/modes/direct_select.js#L15-L20
      //   https://github.com/mapbox/mapbox-gl-draw/blob/3de63e20443192168c5994da51878cb418f17ec5/src/constants.js#L57-L67
      //   https://github.com/urbica/react-map-gl-draw/blob/51d5a26dd61b127742245a4cc881e93551440f21/src/components/Draw/index.js#L303-L316
      map.fire("draw.selectionchange", {
        action,
        parcelId: clickedParcelId,
        reactState: { addedParcelIds },
      })
      // DEV: We initially designed this as an `onSuccess` callback in `map.fire()`
      //   but due to passing through React scheduler for `setFeatures` and MapGL's `requestAnimationFrame`
      //   It was impossible to prevent a noticeable unstyled period for the feature (or possibly double style it)
      //   As a workaround, we've built out our own features layer (so it's same renderer, not passing through map-gl-draw)
      //   and use `useEffect()` to catch same `setFeatures` timing
      onSuccessCallbacks.push(() => {
        _onSuccess()
      })

      // Track that we're on the currently clicked + hovered parcel still (used in hover)
      hoveredAndClickedParcelId = clickedParcelId
    }
  },

  onMouseOut: function (_opts, _e) {
    const hoveredParcelId: number = this.hoveredParcelId
    if (hoveredParcelId && !addedParcelIds?.has(hoveredParcelId)) {
      this.map.removeFeatureState({
        source: "parcels",
        sourceLayer: "parcels",
        id: hoveredParcelId,
      })
    }
  },

  onKeyUp: function (opts, e) {
    if (e.keyCode === 27) return this.changeMode("simple_select")
  },

  toDisplayFeatures: function (opts, geojson, display) {
    display(geojson)
  },
}

ParcelSelectMode.onTap = ParcelSelectMode.onClick

const getLayerPaint = () => {
  // https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#paint-property
  // https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#feature-data
  // https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#case
  // Build fills procedurally (declarative requires separating fill from opacity, which less legible)
  const fillColors: unknown[] = ["case"]
  const fillOpacities: unknown[] = ["case"]

  const FEATURE_STATE_IS_ADDED = ["==", ["feature-state", "added"], true]
  const FEATURE_STATE_IS_ADDING = ["==", ["feature-state", "adding"], true]
  const FEATURE_STATE_IS_HOVERING = ["==", ["feature-state", "hover"], true]
  const FEATURE_STATE_IS_REMOVING = ["==", ["feature-state", "removing"], true]
  let condition

  // State: If we're removing or (hovering an added or adding feature) (thus hovering to remove), then use next line
  // DEV: Order matters, this overrides hover logic
  condition = [
    "any",
    FEATURE_STATE_IS_REMOVING,
    [
      "all",
      FEATURE_STATE_IS_HOVERING,
      ["any", FEATURE_STATE_IS_ADDED, FEATURE_STATE_IS_ADDING],
    ],
  ]
  fillColors.push(condition, "#dc2626") // "fire" in `tailwind.config.js`
  fillOpacities.push(condition, 0.7)

  // State: Otherwise if we're adding, then use next line
  condition = FEATURE_STATE_IS_ADDING
  fillColors.push(condition, "#3bb2d0") // Same as `MapVisualizationDrawStyles`
  fillOpacities.push(condition, 0.6)

  // State: Otherwise, if we're hover, then use next line
  condition = FEATURE_STATE_IS_HOVERING
  fillColors.push(condition, "#3bb2d0") // Same as `MapVisualizationDrawStyles`
  fillOpacities.push(condition, 0.3)

  // State: Default
  fillColors.push("transparent")
  fillOpacities.push(0.0)

  // Build our `paint`
  return {
    "fill-outline-color": "transparent",
    "fill-color": fillColors,
    "fill-opacity": fillOpacities,
  }
}

export const ParcelSelectLayer = ({
  mapRef,
  layer,
  features,
}: ParcelSelectLayerTypes) => {
  // DEV: Technically we could remove `parcel-select-features`, use MapVisualization's <Draw>,
  //   and build this `useEffect` in `MapVisualization`
  //   but that would clutter an already busy file (MapVisualization)
  //   so we're keeping all parcel select code in this file
  //   and hopefully improving comprehensibility
  useEffect(() => {
    // We're about to re-render for changing the `sourcedata` of `parcel-select-features`
    //   so wait until our data is loaded before running our `setFeatureState` callbacks.
    //   Without waiting, we will callback too early and show an empty feature briefly.
    // https://docs.mapbox.com/mapbox-gl-js/api/map/#map.event:sourcedata
    const map = mapRef.current.getMap()
    // DEV: We could access `map` inside our `onSuccessCallbacks`
    //   but that code shouldn't be aware of our implementation with `Source` here (so it isn't)
    // DEV: `sourcedata` event isn't when the data is loaded, but fires multiple times during loading
    //   To make this bulletproof, query `isSourceLoaded` -- https://gis.stackexchange.com/a/282140
    const onceSourceLoaded = () => {
      if (
        map.getSource("parcel-select-features") &&
        map.isSourceLoaded("parcel-select-features")
      ) {
        if (onSuccessCallbacks.length) {
          onSuccessCallbacks.forEach((fn) => fn())
          onSuccessCallbacks = []
        }
        map.off("sourcedata", onceSourceLoaded)
      }
    }
    map.on("sourcedata", onceSourceLoaded)
  }, [features, mapRef])

  const getPaintOrDefault = (
    id: string,
    defaultPaint: Record<string, never>
  ) => {
    const style = mapVisualizationDrawStyles.find((style) => style.id === id)
    return style ? style.paint : defaultPaint
  }

  const getLayoutOrDefault = (
    id: string,
    defaultLayout: Record<string, never>
  ) => {
    const style = mapVisualizationDrawStyles.find((style) => style.id === id)
    return style ? style.layout : defaultLayout
  }

  return (
    <>
      {/* https://docs.mapbox.com/mapbox-gl-js/api/sources/#geojsonsource */}
      <Source
        id="parcel-select-features"
        type="geojson"
        data={{
          type: "FeatureCollection",
          features,
        }}
      />
      <Layer
        id="parcel-select-features-fill"
        type="fill"
        source="parcel-select-features"
        paint={getPaintOrDefault("gl-draw-polygon-fill-inactive", {})}
      />
      <Layer
        id="parcel-select-features-line"
        type="line"
        source="parcel-select-features"
        layout={getLayoutOrDefault("gl-draw-line-inactive", {})}
        paint={getPaintOrDefault("gl-draw-line-inactive", {})}
      />

      {/* Provide our actual custom visuals for this layer */}
      <Source
        id="parcels"
        type="vector"
        tiles={[VECTOR_URL]}
        minzoom={PARCEL_LAYER_MIN_ZOOM}
        maxzoom={17}
        promoteId={{ parcels: "robust_id" }}
      />
      <Layer
        id="parcels-fill"
        type="fill"
        source="parcels"
        source-layer="parcels"
        paint={getLayerPaint()}
      />
      <Layer
        id="parcels-line"
        type="line"
        source="parcels"
        source-layer="parcels"
        paint={{
          "line-color": layer === "aerial" ? "#ffffe0" : "#1b1e23",
          "line-width": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            4,
            2,
          ],
        }}
      />
    </>
  )
}
