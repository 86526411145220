import { CSSProperties, Dispatch, SetStateAction, useState } from "react"
import { Link, useParams } from "react-router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/pro-duotone-svg-icons"
import cx from "classnames"

import EligibilityQuizForm from "./EligibilityQuizForm"
import EligibilityQuizCompleted from "./EligibilityQuizCompleted"
import { useAccountUrlPrefix } from "../../hooks"
import {
  AttestationQuizesTypes,
  QuizAttestationType,
  QuizDataTypes,
} from "@/types/eligibilityQuiz"
import { ProgramTypeStats } from "@/types/eligibility"

interface EligibilityQuizStepsTypes {
  quizesData: AttestationQuizesTypes | undefined
  attestations: QuizAttestationType[] | undefined
  quizData: QuizDataTypes | undefined
  quizStep: number
  setQuizStep: Dispatch<SetStateAction<number | undefined>>
}

const EligibilityQuizSteps = ({
  quizesData,
  attestations,
  quizData,
  quizStep,
  setQuizStep,
}: EligibilityQuizStepsTypes) => {
  const { quizCategory } = useParams()
  const accountUrlPrefix = useAccountUrlPrefix()
  const [completed, setCompleted] = useState<boolean>(false)
  const [numEligible, setNumEligible] = useState<number | undefined>(undefined)
  const [projCategoryStats, setProjCategoryStats] = useState<
    ProgramTypeStats | undefined
  >(undefined)

  const imageUrl =
    attestations && typeof attestations[quizStep]?.image_url === "string"
      ? attestations[quizStep]?.image_url
      : quizData?.image_url

  const someNotCompleted =
    quizesData &&
    Object.values(quizesData).some((quiz) => quiz.completed === false)

  return (
    <div className={cx("pb-24", { "pb-36": completed }, "md:pb-0")}>
      <div className="flex justify-end px-4 sm:px-[72px] py-4 sm:pt-12 sm:pb-[76px]">
        <Link
          to={`${accountUrlPrefix}/programs/page/1`}
          className="quiz-close text-charcoal-900"
          state={{ quizCategory: completed ? quizCategory : undefined }}
        >
          <FontAwesomeIcon
            icon={faXmark}
            style={
              {
                "--fa-primary-opacity": 0,
                "--fa-secondary-opacity": 1,
              } as CSSProperties
            }
          />
        </Link>
      </div>

      <div className="container xl:max-w-[1440px] flex justify-between gap-8">
        <div className="hidden md:block w-auto max-w-[528px]">
          <img
            src={imageUrl}
            alt={quizData?.quiz_type}
            className="w-auto max-h-[502px] md:mb-10"
          />
        </div>

        <div className="md:w-[500px] max-w-full">
          {completed ? (
            <EligibilityQuizCompleted
              someNotCompleted={someNotCompleted}
              numEligible={numEligible}
              projCategoryStats={projCategoryStats}
              quizData={quizData}
              setQuizStep={setQuizStep}
              setCompleted={setCompleted}
            />
          ) : (
            <EligibilityQuizForm
              attestations={attestations}
              quizData={quizData}
              quizStep={quizStep}
              setQuizStep={setQuizStep}
              setCompleted={setCompleted}
              setNumEligible={setNumEligible}
              setProjCategoryStats={setProjCategoryStats}
              imageUrl={imageUrl}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default EligibilityQuizSteps
