import { useEffect, useState } from "react"
import { AxiosError } from "axios"
import { Link, useLocation } from "react-router"
import { useQueryClient } from "@tanstack/react-query"
import {
  useTooltipState,
  Tooltip as ReakitTooltip,
  TooltipReference,
  TooltipArrow,
} from "reakit/Tooltip"

import CheckmarkListItem from "../../components/CheckmarkListItem"
import PremiumCheckmarkList from "../../components/PremiumCheckmarkList "
import { Toast } from "../../components/Toast"
import { useAccount, useAccountId, useCreateStripeSession } from "../../hooks"
import { genericErrMsg } from "../../api/auth"
import BecomeAMemberWidgetOption from "./BecomeAMemberWidgetOption"

const BecomeAMemberWidget = () => {
  const tooltip = useTooltipState()
  const queryClient = useQueryClient()
  const accountId = useAccountId()
  const location = useLocation()
  const [isNcaOnetime, setIsNcaOnetime] = useState<boolean>(false)

  const { data: account, isLoading: accountIsLoading } = useAccount(
    queryClient,
    accountId
  )

  const { mutateAsync: createStripeSession } = useCreateStripeSession(
    queryClient,
    accountId,
    {
      onSuccess: (data: { url: string }) => {
        window.location.href = data.url
      },
      onError: (error) => {
        const axiosError = error as AxiosError
        Toast.error(axiosError?.message || genericErrMsg)
      },
    }
  )

  const handleStripeSubmit = async () => {
    const postData = {
      lookup_key: "basic_annual",
      addon_keys: isNcaOnetime ? ["nca_onetime"] : [],
    }

    await createStripeSession(postData)
  }

  useEffect(() => {
    if (location.state?.includeAdvisor || account?.is_member) {
      setIsNcaOnetime(true)
    }
  }, [account?.is_member, location.state])

  if (accountIsLoading) {
    return null
  }

  return (
    <div className="w-full max-w-[916px] mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <BecomeAMemberWidgetOption
          heading="Basic"
          subheading="Starter membership"
          enabled={!account?.is_member}
          active={!isNcaOnetime}
          onClick={() => {
            !account?.is_member ? setIsNcaOnetime(false) : null
          }}
        >
          <CheckmarkListItem>
            Program-level eligibility for your land
          </CheckmarkListItem>

          <CheckmarkListItem className="mt-3">
            Full access to program contract details
          </CheckmarkListItem>

          <CheckmarkListItem className="mt-3">
            Program stacking recommendations
          </CheckmarkListItem>

          <CheckmarkListItem className="mt-3">
            Loss Detection alerting you of disturbances to your forest
          </CheckmarkListItem>

          <CheckmarkListItem className="mt-3">
            Expert email support
          </CheckmarkListItem>
        </BecomeAMemberWidgetOption>

        <BecomeAMemberWidgetOption
          heading="Premium"
          subheading="Includes everything in Basic, plus:"
          active={isNcaOnetime}
          onClick={() => {
            setIsNcaOnetime(true)
          }}
        >
          <PremiumCheckmarkList />
        </BecomeAMemberWidgetOption>
      </div>

      <h3 className="text-charcoal-500 text-2xl font-medium leading-[124%] tracking-0.48 mt-4">
        Price Today: {isNcaOnetime ? "$408" : "$108"}
      </h3>

      <p className="text-dusk text-lg leading-[140%] tracking-0.36 mt-1">
        Membership renews annually at the Basic Member rate and can be canceled
        anytime.
      </p>

      <TooltipReference {...tooltip} className="ring-0">
        <button
          className="member-widget-join-now max-w-40 btn2 btn2-primary font-semibold btn2-block mt-4 mb-0"
          onClick={() => {
            handleStripeSubmit()
          }}
          disabled={account?.role !== "admin"}
        >
          Pay Now
        </button>
      </TooltipReference>

      {account?.role !== "admin" ? (
        <ReakitTooltip {...tooltip} className="st-tooltip">
          <div className="text-sm max-w-sm p-3 st-tooltip-inner">
            <TooltipArrow {...tooltip} />
            Only admins can subscribe.
          </div>
        </ReakitTooltip>
      ) : null}

      <Link
        to="/"
        className="member-widget-dashboard-link inline-block text-lg text-leaf leading-[140%] tracking-0.36 mt-3"
      >
        No thanks, bring me to my Dashboard
      </Link>
    </div>
  )
}

export default BecomeAMemberWidget
