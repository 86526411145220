import { useQueryClient } from "@tanstack/react-query"

import {
  useAccountId,
  useAccountNotifications,
  useAccountUrlPrefix,
} from "../../hooks"
import { NotificationTypes } from "@/types/notifications"
import { Link } from "react-router"

const PartnerInvites = () => {
  const accountId = useAccountId()
  const queryClient = useQueryClient()
  const accountUrlPrefix = useAccountUrlPrefix()

  const { data: notifications, isLoading: notificationsIsLoading } =
    useAccountNotifications<NotificationTypes[], Error>(queryClient, accountId)

  if (notificationsIsLoading) {
    return null
  }

  const unreadNotifications = (notifications || []).filter(
    (notification) => notification.status === "new"
  )

  const heading = unreadNotifications?.length
    ? "Exclusive Invites Waiting"
    : "No Invites Yet"

  const destination = unreadNotifications?.length
    ? "View Invitations"
    : "View Inbox"

  return (
    <div className="card card-content px-6 pt-6 pb-4 mb-6">
      <h4 className="text-overline text-dusk-500">Partner Invites</h4>

      <p className="text-charcoal-500 text-lg font-bold leading-130 tracking-0.378 mt-2">
        {heading}
      </p>

      {unreadNotifications?.length > 0 && (
        <p className="label leading-18px font-normal tracking-0.32 mt-2">
          You have <strong>{notifications?.length} pending invitations</strong>{" "}
          to connect from program partners who want to learn about your land
          goals and discuss their program.
        </p>
      )}
      {unreadNotifications?.length == 0 && (
        <p className="label leading-18px font-normal tracking-0.32 mt-2">
          Look out for exclusive invites from program partners interested in
          your land.
        </p>
      )}

      <Link
        to={`${accountUrlPrefix}/inbox`}
        className="view-inbox btn2 btn2-primary font-semibold mt-2"
      >
        {destination}
      </Link>
    </div>
  )
}

export default PartnerInvites
