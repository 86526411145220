import { ReactNode } from "react"
import cx from "classnames"
import { CloseButton } from "./CloseButton"

interface StickyNotificationTypes {
  title: string | null
  className?: string | null
  children: ReactNode
  variant?: "warning" | "success"
  onDismiss?: () => void
  sticky?: boolean
}

export const StickyNotification = ({
  title,
  className,
  children,
  variant = "warning",
  onDismiss,
  sticky = false,
}: StickyNotificationTypes) => (
  <div
    className={cx(
      "flex flex-row text-white relative z-9 py-3",
      { "bg-orange-900": variant === "warning" },
      { "bg-grass-900": variant === "success" },
      { "sticky top-0": sticky },
      className
    )}
  >
    <div className="container flex-auto">
      {title && (
        <div className="mb-2 pr-3">
          <strong className="font-semibold">{title}</strong>
        </div>
      )}
      <div>{children}</div>
    </div>
    {onDismiss && (
      <div className="absolute top-3 right-3">
        <CloseButton
          aria-label="Dismiss notification"
          onClick={onDismiss}
          white
        />
      </div>
    )}
  </div>
)

export default StickyNotification
