import { DateTime } from "luxon"

import Stat from "../../../../components/Stat"

interface LossDetectionStatsProps {
  date: string | undefined
  majorLoss: string | number
  minorLoss: string | number
  isMember: boolean
}

const LossDetectionStats = ({
  date,
  majorLoss,
  minorLoss,
  isMember,
}: LossDetectionStatsProps) => {
  const formattedDate = date
    ? DateTime.fromISO(date).toLocaleString({
        month: "short",
        day: "numeric",
        year: "numeric",
      })
    : "-"

  const blur = isMember ? "" : "blur-sm"

  return (
    <div className="w-full inline-flex flex-wrap gap-6 lg:gap-9">
      <Stat
        label="Detection Date"
        value={formattedDate}
        showPct={false}
        valueClass={blur}
      />

      <Stat
        label="Acres of Major Loss"
        value={majorLoss}
        showPct={false}
        valueClass={blur}
      />

      <Stat
        label="Acres of Minor Loss"
        value={minorLoss}
        showPct={false}
        valueClass={blur}
      />
    </div>
  )
}

export default LossDetectionStats
