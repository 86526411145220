import { DialogStateReturn } from "reakit/Dialog"

interface ProjectMapHeaderTypes {
  dialog: DialogStateReturn
}

const ProjectMapHeader = ({ dialog }: ProjectMapHeaderTypes) => (
  <div className="mb-7">
    <hr className="w-full border-charcoal-50 mb-12" />

    <h4 className="mb-6 text-2xl leading-120 tracking-0.48">Eligibility Map</h4>

    <p className="text-charcoal-500 text-base leading-130 tracking-0.32">
      Understand the eligible acres on your land.
    </p>

    <p className="mt-1">
      <button
        type="button"
        className="link how-assess-land-link"
        onClick={dialog.show}
      >
        Learn how we assess your land
      </button>
    </p>
  </div>
)

export default ProjectMapHeader
