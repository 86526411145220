interface MembershipBannerHeadingProps {
  isTimerComplete: boolean
  isFlagEnabled: boolean
}

const MembershipBannerHeading = ({
  isTimerComplete,
  isFlagEnabled,
}: MembershipBannerHeadingProps) => {
  if (isTimerComplete) {
    return (
      <h3 className="text-autumn text-xl font-medium leading-7 tracking-0.4">
        Don't miss out on opportunities.
      </h3>
    )
  }

  if (isFlagEnabled) {
    return (
      <h3 className="text-autumn text-xl font-medium leading-7 tracking-0.4">
        Become a member to protect your property and unlock thousands in market
        opportunities.
      </h3>
    )
  }

  return (
    <h3 className="text-autumn text-xl font-medium leading-7 tracking-0.4">
      Become a member to unlock access to full program details for all programs
      and loss detection for your land.
    </h3>
  )
}

export default MembershipBannerHeading
