import { Link } from "react-router"
import { useAccountId, useIsMultiAccount } from "../../hooks"
import { NotificationTypes } from "../../types/notifications"

interface MessagePreviewTypes {
  invite: NotificationTypes
}

const MessagePreview = ({ invite }: MessagePreviewTypes) => {
  const accountId = useAccountId()
  const isMultiAccount = useIsMultiAccount()

  return (
    <Link
      className="bg-white p-4 flex gap-4 rounded"
      to={
        isMultiAccount
          ? `/accounts/${accountId}/programs/${invite.extra_fields?.project_id}`
          : `/programs/${invite.extra_fields?.project_id}`
      }
    >
      <div className="flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
        >
          <circle cx="4" cy="4" r="4" fill="#C22323" />
        </svg>
      </div>

      <div className="max-w-full-94">
        <h4 className="text-dusk text-sm font-normal leading-130 tracking-0.14">
          <span className="text-leaf font-semibold">
            {invite.extra_fields?.project_name}
          </span>{" "}
          sent you an invite
        </h4>

        <p className="text-charcoal-500 text-xs leading-130 mt-1 break-words">
          {invite.extra_fields?.message}
        </p>
      </div>

      <div className="w-[54px] h-[54px] rounded bg-gray-500 overflow-hidden shrink-0 mr-0 ml-auto">
        <img
          src={invite.extra_fields?.image_url}
          alt={invite.extra_fields?.project_name}
          className="w-[54px] h-[54px] block object-cover rounded"
        />
      </div>
    </Link>
  )
}

export default MessagePreview
