import { Link } from "react-router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconDefinition as IconDefinitionSvg } from "@fortawesome/fontawesome-svg-core"
import cx from "classnames"

interface IconLinkTypes {
  to: string
  icon: IconDefinitionSvg
  text: string
  external?: boolean
  className: string
}

const IconLink = ({
  to,
  icon,
  text,
  external = false,
  className,
}: IconLinkTypes) => (
  <Link
    to={to}
    className={cx("block text-leaf font-semibold hover:underline", className)}
    target={external ? "_blank" : "_self"}
    rel={external ? "noopener noreferrer" : undefined}
  >
    <span className="inline-block mr-1 w-6 leading-6 text-center">
      <FontAwesomeIcon icon={icon} />
    </span>
    {text}
  </Link>
)

export default IconLink
